import { createSlice } from "@reduxjs/toolkit";

export const MessageSlice = createSlice({
  name: "MessageSlice",
  initialState: {
    messagelist: [],
    messageType: "chatwidget",
    selectedChat: {},
  },
  reducers: {
    updateMessageList: (state, actions) => {
      if (actions) {
        state.messagelist = actions.payload;
      }
    },
    updateMessageType: (state, actions) => {
      if (actions) {
        state.messageType = actions.payload;
      }
    },
    updateSelectedChat: (state, actions) => {
      if (actions) {
        state.selectedChat = actions.payload;
      }
    },
  },
});

export const { updateMessageList, updateMessageType, updateSelectedChat } = MessageSlice.actions;
export default MessageSlice.reducer;
