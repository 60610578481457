import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import PropTypes from "prop-types";
import LoadingAnimation from "../lotties/LoadingAnimation.json";

function TableLoader({ data }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <LoaderWrapper data={data}>
      <Lottie options={defaultOptions} width={250} height={50} />
      <p>Loading...</p>
    </LoaderWrapper>
  );
}

export default TableLoader;

TableLoader.propTypes = {
  data: PropTypes.any,
};

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  height: ${({ data }) => (data?.length > 0 ? "70%" : "100%")};
  padding-top: ${({ data }) => (data?.length > 0 ? "25px" : "190px")};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-family: "Poppins";
    color: #296eff;
    font-weight: 700;
  }
`;
