import "./style/global.css";
import { message } from "antd";
import { createContext } from "react";
import { AppRoutes } from "./routes/Routes";

export const MessageContext = createContext();
function App() {
  const [messageApi, contextHolder] = message.useMessage();
  return (
    <div>
      {contextHolder}
      <MessageContext.Provider value={messageApi}>
        <AppRoutes />
      </MessageContext.Provider>
    </div>
  );
}

export default App;
