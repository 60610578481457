import { MessageErrorIcon } from "./SvgIcons";

export const error = (messageApi, content) => {
  messageApi.open({
    type: "error",
    content: content,
    // duration: 1000,
    className: "custom-class-error",
    icon: <MessageErrorIcon />,
    style: {
      color: "#fff",
    },
  });
};
