import { deleteApi, getApi, patchApi, postApi, putApi } from "./ApiMethods";
import { postInternalApi } from "./InternalServices/ApiMethods";

const LOGIN_ROUTE = "login/v2";
const COMPANY_LIST = "company";
const PODIUM_GET_URL = "podium";
const GET_AUTH_URL = "get-auth-url";
const SAVE_PODIUM_TOKEN = "save-token";
const SET_LOCATION = "set-location";
const PODIUM_LISTING = "mappings";
const INTERNAL_LOGIN = "aiBooking/aiBookingUserLogin";
const FORGOT_PASS = "aiBooking/forgotPassword";
const RESET_PASSWORD = "aiBooking/resetPassword";
const CHANGE_PASSWORD = "aiBooking/changePassword";
const CREATE_CRM = "crm";
const BOOKING_LIST = "booking";
const YELP_GET_URL = "yelp";
const YELP_GET_AUTH_URL = "get-auth-url";
const SAVE_YELP_TOKEN = "set-yelp-token";
const GET_YELP_USER_LIST = "yelp/users";
const GET_COMPANY_USER = "company/users";
const GET_MESSAGES = "messaging";

export const authLogin = (payload) => postApi(LOGIN_ROUTE, payload);

export const getCompanyListing = (params, payload) =>
  patchApi(`${COMPANY_LIST}${params && "?"}${params}`, payload);

export const deleteCompany = (id) => deleteApi(`${COMPANY_LIST}/${id}`);

export const createCompany = (payload) => postApi(COMPANY_LIST, payload);

export const editCompany = (id, payload) =>
  putApi(`${COMPANY_LIST}/${id}`, payload);

export const redirectPodium = (id) =>
  getApi(`${PODIUM_GET_URL}/${id}/${GET_AUTH_URL}`);

export const savePodiumToken = (payload) =>
  postApi(`${PODIUM_GET_URL}/${SAVE_PODIUM_TOKEN}`, payload);

export const setLocationPodium = (payload) =>
  postApi(`${PODIUM_GET_URL}/${SET_LOCATION}`, payload);

export const getPodiumListing = (params, payload) =>
  patchApi(`${PODIUM_GET_URL}/${PODIUM_LISTING}?${params}`, payload);

export const internalLogin = (payload) =>
  postInternalApi(INTERNAL_LOGIN, payload);

export const forgotpass = (payload) => postInternalApi(FORGOT_PASS, payload);

export const setNewPassword = (payload) =>
  postInternalApi(RESET_PASSWORD, payload);

export const changePassword = (payload) =>
  postInternalApi(CHANGE_PASSWORD, payload);

export const connectCrm = (payload) => postApi(CREATE_CRM, payload);

export const listConnectedCRM = (id, params, payload) =>
  patchApi(`${COMPANY_LIST}/${id}/${CREATE_CRM}?${params}`, payload);

export const deleteCRMForCompany = (id) => deleteApi(`${CREATE_CRM}/${id}`);

export const updateCrmCompany = (id, payload) =>
  putApi(`${CREATE_CRM}/${id}`, payload);

export const getBookingList = (id, params, payload) =>
  patchApi(`${COMPANY_LIST}/${id}/${BOOKING_LIST}?${params}`, payload);

export const getYelpAuthURL = (payload) =>
  postApi(`${YELP_GET_URL}/${YELP_GET_AUTH_URL}`, payload);

export const saveYelpToken = (payload) =>
  postApi(`${YELP_GET_URL}/${SAVE_YELP_TOKEN}`, payload);

export const getYelpList = (company_id) =>
  getApi(`${YELP_GET_URL}/${COMPANY_LIST}/${company_id}/${YELP_GET_URL}`);

export const getYelpUsersList = (payload, bodyRequest) =>
  patchApi(`${GET_YELP_USER_LIST}?${payload}`, bodyRequest);

export const updateYelpUser = (id, payload) =>
  putApi(`${GET_YELP_USER_LIST}/${id}`, payload);

export const getCompanyUsersList = (payload, bodyRequest) =>
  patchApi(`${GET_COMPANY_USER}?${payload}`, bodyRequest);

export const createCompanyUsers = (payload) =>
  postApi(GET_COMPANY_USER, payload);

export const editCompanyUsers = (id, payload) =>
  putApi(`${GET_COMPANY_USER}/${id}`, payload);

export const deleteCompanyUser = (id) => deleteApi(`${GET_COMPANY_USER}/${id}`);

export const getMessages = (type, params, filterPayload) =>
  patchApi(`${GET_MESSAGES}/${type}/list?${params}`, filterPayload);

const AI_COMPANT_STATISTICS = "statistics";
export const aiCompanyStatistics = (id, payload) => {
  return getApi(`${AI_COMPANT_STATISTICS}/${id}/total?${payload}`);
};

const GET_USERS_LIST = "users";
export const getUsersList = (path, payload, bodyRequest) => {
  return patchApi(`${GET_USERS_LIST}/${path}/users?${payload}`, bodyRequest);
};

export const updateUserStatus = (type, id, payload) => {
  return putApi(`${GET_USERS_LIST}/${GET_USERS_LIST}/${type}/${id}`, payload);
};
