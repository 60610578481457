import { MessageSuccessIcon } from "./SvgIcons";

export const success = (messageApi, content) => {
  messageApi.open({
    type: "success",
    content,
    // duration: 1000,
    className: "custom-class",
    icon: <MessageSuccessIcon />,
    style: {
      color: "#fff",
    },
  });
};
