import Api from "./Interceptor";

// get Api
export const getInternalApi = async (url) => {
  try {
    const result = await Api.get(url);
    if (result.status === 200) {
      if (result.data.status === 200 || result?.data?.message == "OK") {
        return result.data;
      }
      return result.data.message;
    }
  } catch (e) {
    if (e) {
      return e;
    }
  }
};

// post Api
export const postInternalApi = async (url, data) => {
  try {
    const result = await Api.post(url, data);
    if (result.status === 200) {
      if (result.data.status === 200) {
        return result.data;
      }
      return result.data.message;
    }
  } catch (e) {
    if (e) {
      return e;
    }
  }
};

// put api

// put Api
export const putInternalApi = async (url, data) => {
  try {
    const result = await Api.put(url, data);
    if (result.status === 200) {
      if (result.data.status === 200) {
        return result.data;
      }
      return result.data.message;
    }
  } catch (e) {
    if (e) {
      return e;
    }
  }
};

// patch Api
export const patchInternalApi = async (url, data) => {
  try {
    const result = await Api.patch(url, data);
    if (result.status === 200) {
      if (result.data.status === 200) {
        return result.data;
      }
      return result.data.message;
    }
  } catch (e) {
    if (e) {
      return e;
    }
  }
};

// delete Api
export const deleteInternalApi = async (url, data) => {
  try {
    const result = await Api.delete(url, { params: data });
    if (result.status === 200) {
      if (result.data.status === 200) {
        return result.data;
      }
      return result.data.message;
    }
  } catch (e) {
    if (e) {
      return e;
    }
  }
};
