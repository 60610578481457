import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Popover, message } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  OpenSideNav,
  selectedAiCompany,
  SignInAction,
} from "../auth/LoginSlice";
import { BackSVGIcon, Logouticon } from "../utils/SvgIcons";
import { Sidebarlogo } from "../utils/Images";
import ChangePasswordModal from "../modals/ChangePasswordModal";
import { updateMessageType, updateSelectedChat } from "../store/MessageSlice";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedinUser = useSelector((state) => state?.LoginSlice?.data);
  const isSideBarOpen = useSelector((state) => state?.LoginSlice?.sideNav);
  const location = useLocation().pathname;
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [popoverChange, setPopoverChange] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const getName = useLocation()?.state;
  const { company_id } = useParams();

  const handlePopover = (val) => {
    setPopoverChange(val);
  };

  const handleName = (location) => {
    if (location?.includes("sms")) {
      return "Manage SMS Users";
    }
    if (location?.includes("lsa")) {
      return "Manage LSA Users";
    }
    if (location?.includes("ai-booking-stats")) {
      return "Booking Buddy Performance";
    }
    if (location?.includes("chats")) {
      if (company_id) {
        return getName?.selectedCompany?.label;
      } else {
        return "Conversations";
      }
    }
    if (location?.includes("company-users")) {
      return "Company Users";
    }
    if (location?.includes("yelps")) {
      return "Manage Yelp Users";
    }
    if (location?.includes("booking")) {
      return "Bookings";
    }
    if (
      location.includes("dashboard") ||
      location.includes("podium/oauth/callback") ||
      location.includes("yelp")
    ) {
      return "Companies";
    }
    if (
      location.includes("podium") &&
      !location.includes("podium/oauth/callback")
    ) {
      return "Podium";
    }
    if (location.includes("crm")) {
      return "Connect CRM";
    }
    return "Data Table";
  };

  function LogoutContent() {
    return (
      <>
        <div
          onClick={() => {
            dispatch(SignInAction(null));
            dispatch(updateMessageType("chatwidget"));
            dispatch(updateSelectedChat(null));
            dispatch(selectedAiCompany(null));
            navigate("/");
          }}
          className="logout-box"
        >
          <Logouticon />
          <p>Logout</p>
        </div>
        {/* {!loggedinUser?.isCompanyUser && (
          <div
            onClick={() => {
              setChangePasswordModal(true);
              setPopoverChange(false);
            }}
            className="logout-box"
          >
            <ChangePasswordIcon />

            <p style={{ whiteSpace: "nowrap" }}>Change Password</p>
          </div>
        )} */}
      </>
    );
  }

  return (
    <HeaderWrapper>
      {contextHolder}
      {changePasswordModal && (
        <ChangePasswordModal
          open={changePasswordModal}
          handleClose={() => setChangePasswordModal(false)}
          messageApi={messageApi}
        />
      )}
      <div className="header">
        <div
          className="dashboard-head"
          style={{ marginLeft: isSideBarOpen ? "185px" : 0 }}
        >
          {company_id ? (
            <div className="svg-box" onClick={() => navigate(-1)}>
              <BackSVGIcon />
            </div>
          ) : (
            <img
              src={Sidebarlogo}
              alt=""
              onClick={() => dispatch(OpenSideNav(!isSideBarOpen))}
            />
          )}
          <h1>{handleName(location)}</h1>
        </div>
        <Popover
          prefixCls="logoutPopup"
          content={LogoutContent()}
          trigger="click"
          open={popoverChange}
          onOpenChange={handlePopover}
          placement="bottomRight"
        >
          <div style={{ cursor: "pointer" }} className="profileDiv">
            {/* <img src={adLogo} alt="" /> */}
            <div>
              <p style={{ cursor: "pointer" }}>
                {loggedinUser?.user ?? loggedinUser?.name}
              </p>
              {/* <HeaderDownArrow /> */}
            </div>
          </div>
        </Popover>
      </div>
    </HeaderWrapper>
  );
}

export default Header;
const HeaderWrapper = styled.div`
  .svg-box {
    cursor: pointer;
    svg {
      fill: #fff;
    }
  }
  .header {
    background: #296eff;
    ${"" /* background:#F98229; */}
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;

    .dashboard-head {
      display: flex;
      gap: 24px;
      transition: all 0.5s;
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      h1 {
        color: #fff;
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 600;
        margin: 0px;
      }
    }
  }
  .profileDiv {
    background: rgba(246, 246, 246, 0.4);
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    gap: 8px;

    img {
      width: 35px !important;
      height: 35px !important;
    }
    div {
      display: flex;
      align-items: center;
      gap: 4px;
      p {
        margin: 0px;
        color: #fff;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 500;
      }
      svg {
        cursor: pointer;
      }
    }
    select {
      border-radius: 40px;
      background: transparent;
      border: none;
      color: #fff;
    }
  }
`;
