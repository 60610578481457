import { Spin, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

import { saveAs } from "file-saver";
import moment from "moment";
import AiFilter from "./AiFilter";
// import SubscriptionCheck from "../../layouts/SubscriptionCheck";
import {
  aiCompanyStatistics,
  getCompanyListing,
} from "../../services/Collection";
import { selectedAiCompany } from "../../auth/LoginSlice";
import { useNavigate } from "react-router-dom";
import { updateMessageType } from "../../store/MessageSlice";
import { error } from "../../utils/ApiErrorMessage";
import { MessageContext } from "../../App";
import { valueFormatter } from "../../utils/CommonFunction";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 40,
    }}
    spin
  />
);

export default function AiBooking() {
  const dispatch = useDispatch();
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const [loading, setLoading] = useState(false);
  const [selectedLoading, setSelectedLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const selectedCompany = useSelector(
    (state) => state?.LoginSlice?.selectedCompany
  );
  const messageApi = useContext(MessageContext);
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const AiDate = useSelector((state) => state?.LoginSlice);
  const [cStatsData, setCStatsData] = useState([]);
  const [filterDrawers, setFilterDrawers] = useState(false);
  const activeTab = useSelector((state) => state?.LoginSlice?.activeTab);
  const navigate = useNavigate();

  const handleConversation = (data) => {
    if (data?.name === "Yelp") {
      navigate(`/chats/${selectedCompany?.value}`, {
        state: { selectedCompany },
      });
      dispatch(updateMessageType("yelp"));
    } else if (data?.name === "LSA") {
      navigate(`/chats/${selectedCompany?.value}`, {
        state: { selectedCompany },
      });
      dispatch(updateMessageType("lsa"));
    } else if (data?.name === "Web Chat") {
      navigate(`/chats/${selectedCompany?.value}`, {
        state: { selectedCompany },
      });
      dispatch(updateMessageType("chatwidget"));
    } else {
      navigate(`/chats/${selectedCompany?.value}`, {
        state: { selectedCompany },
      });
      dispatch(updateMessageType("sms"));
    }
  };

  const columns = [
    {
      title: "Source",
      dataIndex: "name",
      width: 150,
      fixed: "left",
    },
    {
      title: "Total Leads",
      dataIndex: "total_leads",
      width: 100,
      render: (val, data) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            data?.name !== "Total" && handleConversation(data);
          }}
        >
          {val}
        </span>
      ),
    },
    {
      title: "Total Responses",
      dataIndex: "total_conversation",
      width: 100,
    },
    {
      title: "Total Bookings",
      dataIndex: "total_booking",
      width: 100,
    },
    {
      title: "Total Sales",
      dataIndex: "total_sales",
      width: 100,
      render: (val) => (
        <p style={{ margin: "0px", whiteSpace: "nowrap" }}>{`$${valueFormatter(
          val,
          1
        )}`}</p>
      ),
    },
    {
      title: "Total Revenue",
      dataIndex: "total_revenue",
      width: 100,
      render: (val) => (
        <p style={{ margin: "0px", whiteSpace: "nowrap" }}>{`$${valueFormatter(
          val,
          1
        )}`}</p>
      ),
    },
  ];

  const invoiceColumns = [
    {
      title: "Source",
      dataIndex: "name",
      width: 150,
      fixed: "left",
    },
    {
      title: "Total Leads",
      dataIndex: "total_leads",
      width: 100,
      render: (val, data) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleConversation(data)}
        >
          {val}
        </span>
      ),
    },
    {
      title: "Total Bookings",
      dataIndex: "total_booking",
      width: 100,
    },
    {
      title: "Total Cost",
      dataIndex: "total_cost",
      width: 100,
      render: (val) => <span>${valueFormatter(val, 1)}</span>,
    },
  ];

  const handleCompanyListing = async () => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append("page", 1);
    params.append("page_size", 100);
    let res = await getCompanyListing(params?.toString());
    if (res?.status == 200) {
      let result = res?.data?.map((c) => ({ value: c?.id, label: c?.name }));
      let updatedArray = result?.find(
        (el) => el?.value === userDetails?.company_id
      );

      setCompanyList(result);
      if (userDetails?.isCompanyUser) {
        dispatch(selectedAiCompany(updatedArray));
        getStatistics(updatedArray);
      } else {
        if (result?.length > 0 && selectedCompany === null) {
          dispatch(selectedAiCompany(result?.[0]));
          getStatistics(result?.[0]);
        } else {
          getStatistics(selectedCompany);
        }
      }
      setLoading(false);
    } else {
      setLoading(false);
      let message =
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong";
      error(messageApi, message);

      //   toast.error(message, { theme: "colored" });
    }
  };

  const calculateTotalCost = (data) => {
    let total =
      (data?.total_leads - data?.total_booking) * 2 + data?.total_booking * 25;
    return total;
  };

  const getStatistics = async (company) => {
    var enddate = new Date();
    var startdate = new Date(new Date().setDate(enddate.getDate() - 30));

    setSelectedLoading(true);
    const requestPayload = new URLSearchParams();
    requestPayload.append(
      "start_time",
      moment(AiDate?.startDate ?? startdate).format("YYYY-MM-DD")
    );
    requestPayload.append(
      "end_time",
      moment(AiDate?.endDate ?? enddate).format("YYYY-MM-DD")
    );

    let res = await aiCompanyStatistics(company?.value, requestPayload);

    res = { data: res, status: 200 };

    if (res?.status == 200) {
      let tempArray = [];
      for (let i = 0; i < res?.data.length; i++) {
        let element = res?.data[i];
        tempArray.push({
          ...element,
          total_booking_perc:
            element?.total_leads == 0
              ? 0
              : (element?.total_booking / element?.total_leads) * 100,
          total_cost: calculateTotalCost(element),
        });
      }
      setCStatsData(tempArray);
      setSelectedLoading(false);
    } else {
      dispatch(selectedAiCompany(null));
      setCStatsData([]);
      setSelectedLoading(false);
      let message =
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong";
      error(messageApi, message);

      //   toast.error(message, { theme: "colored" });
    }
  };

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const rows = data.map((obj) =>
      headers.map((header) => obj[header]).join(",")
    );
    return [headers.join(","), ...rows].join("\n");
  };

  const exportToCSV = (data) => {
    const csvString = convertToCSV(data);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "invoice.csv");
  };

  const handleExport = (data) => {
    let newArray = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      let obj = {
        ["Source"]: element?.name,
        ["Total Leads"]: element?.total_leads,
        ["Total Bookings"]: element?.total_booking,
        ["Total Cost"]: "$" + element?.total_cost,
      };
      newArray.push(obj);
    }
    exportToCSV(newArray);
  };

  useEffect(() => {
    handleCompanyListing();
  }, []);

  return (
    <AiBookingWrapper selectedThemeColors={selectedThemeColors}>
      {/* <SubscriptionCheck /> */}
      {filterDrawers && (
        <AiFilter
          filterDrawers={filterDrawers}
          setFilterDrawers={setFilterDrawers}
          setCStatsData={setCStatsData}
          companyList={companyList}
          initialLoading={selectedLoading}
          isCompanyUser={userDetails}
        />
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        {selectedCompany && (
          <>
            <h3 className="client-name">Company - {selectedCompany?.label}</h3>{" "}
          </>
        )}
        {selectedCompany && (
          <div className="filterBtn-Box">
            {activeTab == "invoice" && (
              <button
                className={`${(selectedLoading || loading) && "disable"}`}
                onClick={() =>
                  !selectedLoading && !loading && handleExport(cStatsData)
                }
              >
                Export
              </button>
            )}
            <button onClick={() => setFilterDrawers(true)}>Filters</button>
          </div>
        )}
      </div>

      <div>
        <div className="company-dropdown">
          {/* <div>
            <h6>Company</h6>
            <Select
              prefixCls="inner-select2"
              value={selectedCompany}
              onChange={(_, data) => dispatch(selectedAiCompany(data))}
              style={{
                width: "100%",
                height: "48px",
                marginTop: "6px",
              }}
              maxTagCount={1}
              placeholder="Choose Company"
              options={companyList}
            />
          </div> */}

          <div>
            {/* <RangeWrapper>
              <RangeSelectorConfirm
                defaultDate={[
                  moment(AiDate?.startDate).format("yyyy-MM-DD"),
                  moment(AiDate?.endDate).format("yyyy-MM-DD"),
                ]}
                handleChange={(d) => {
                  const start = moment(d[0]).format("yyyy-MM-DD");
                  const end = moment(d[1]).format("yyyy-MM-DD");
                  setCurrentDate({ start, end });
                }}
              />
            </RangeWrapper> */}
          </div>
        </div>
      </div>

      {loading || selectedLoading ? (
        <div
          style={{
            width: "100%",
            height: "450px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className="table">
          {selectedCompany == null ? (
            <div className="not-selected-yet">
              Kindly select a company for AI Booking Stats.
            </div>
          ) : (
            <>
              {activeTab == "bookingStats" ? (
                <Table
                  prefixCls="campaignTable"
                  columns={columns}
                  dataSource={cStatsData}
                  pagination={false}
                />
              ) : (
                <Table
                  prefixCls="campaignTable"
                  columns={invoiceColumns}
                  dataSource={cStatsData}
                  pagination={false}
                />
              )}
            </>
          )}
        </div>
      )}
    </AiBookingWrapper>
  );
}

const AiBookingWrapper = styled.div`
  padding: 30px !important;
  height: calc(100vh - 82px);

  .btn-freetrial {
    background: red;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    padding: 2px 5px;
    white-space: nowrap;
    position: absolute;
    top: 10px;
    right: -80px;
    font-family: "Poppins", sans-serif;
  }

  .client-name {
    color: #000;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 25px;
  }
  .company-dropdown {
    width: 312px;
  }
  .filterBtn-Box {
    margin-bottom: 0;
    display: flex;
    gap: 10px;

    button {
      border-style: none;
      background: #296eff !important;
      color: #fff;
      padding: 7px 20px;
      font-size: 17px;
      border-radius: 7px;
      margin-bottom: 0px;
      cursor: pointer;
      font-family: "Poppins";
    }
    .btnColor {
      color: black;
      background: white !important;
      border: 1px solid;
      box-shadow: 5px 7px 7px 0px rgb(0 0 0 / 30%);
    }
    .disable {
      cursor: not-allowed;
      background: #7ba4fb !important;
    }
  }

  .table {
    margin: 20px 0;
    transition: all 0.3s ease 0s;
    min-height: 400px;
    .not-selected-yet {
      width: 100%;
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(0, 0, 0);
      font-size: 28px;
      text-align: center;
    }
  }
  .campaignTable {
    transition: all 0.3s ease 0s;
    .campaignTable-thead {
      .campaignTable-cell {
        background: ${({ selectedThemeColors }) =>
          selectedThemeColors?.headerColor} !important;
      }
    }
    .campaignTable-tbody {
      .campaignTable-cell {
        background: ${({ selectedThemeColors }) =>
          selectedThemeColors?.CardsColor};
        color: ${({ selectedThemeColors }) =>
          selectedThemeColors?.textPrimaryColor};
        border-bottom: 1px solid rgba(232, 232, 237, 1) !important;
      }
    }
    .campaignTable-container {
      max-height: calc(100vh - 230px) !important;
      overflow: auto !important;
    }
    .campaignTable-thead {
      position: sticky !important;
      top: 0 !important;
      z-index: 99 !important;
    }
  }
`;

// const RangeWrapper = styled.div`
//   width: 250px;
//   padding: 0 10px;
//   .ant-picker-range-separator {
//     filter: none !important;
//   }
// `;
