import { createSlice } from "@reduxjs/toolkit";

export const LoginSlice = createSlice({
  name: "LoginSlice",
  initialState: {
    data: null,
    sideNav: false,
    selectedCompany: null,
    activeTab: "bookingStats",
    startDate: null,
    endDate: null,
  },
  reducers: {
    SignInAction: (state, actions) => {
      if (actions) {
        state.data = actions.payload;
      }
    },
    OpenSideNav: (state, action) => {
      if (action) {
        state.sideNav = action.payload;
      }
    },
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    selectedAiCompany: (state, action) => {
      state.selectedCompany = action?.payload;
    },
    updateDate: (state, action) => {
      state.startDate = action?.payload?.date_start;
      state.endDate = action?.payload?.date_end;
    },
  },
});

export const {
  SignInAction,
  OpenSideNav,
  updateDate,
  updateActiveTab,
  selectedAiCompany,
} = LoginSlice.actions;
export default LoginSlice.reducer;
