import { ConfigProvider, Modal, Select, Switch, TimePicker } from "antd";
import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import InputField from "../validations/InputField";
import ErrorMessage from "../utils/ErrorMessage";
import { createCompany, editCompany } from "../services/Collection";
import { error } from "../utils/ApiErrorMessage";
import { success } from "../utils/ApiSuccessMessage";
import { timezonesList } from "../utils/Timezonejson";
import { DropDownIcon } from "../utils/SvgIcons";
const { Option } = Select;

const SourceOption = [
  {
    label: "Yelp",
    value: "yelp",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "Web",
    value: "web",
  },
];

const WorkingDaysOption = [
  {
    label: "Monday",
    value: "Mon",
  },
  {
    label: "Tuesday",
    value: "Tue",
  },
  {
    label: "Wednesday",
    value: "Wed",
  },
  {
    label: "Thurday",
    value: "Thu",
  },
  {
    label: "Friday",
    value: "Fri",
  },
  {
    label: "Saturday",
    value: "Sat",
  },
  {
    label: "Sunday",
    value: "Sun",
  },
];

export function AddCompanyModal({
  open,
  handleClose,
  modalType,
  editDetails,
  messageApi,
  listingApi,
  setFilterData,
  setInitialValues,
}) {
  const [loading, setLoading] = useState(false);
  const selectRef = useRef(null);
  const [dropDownVisible, setDropDownVisible] = useState(false);
  console.log(selectRef, "----selectRef");

  const initialValues = {
    email: editDetails?.email ? editDetails?.email : "",
    phone: editDetails?.phone ? editDetails?.phone : "",
    companyName: editDetails?.name ? editDetails?.name : "",
    sourceType: editDetails?.type ? editDetails?.type : null,
    offerType: editDetails?.type_of_offer ? editDetails?.type_of_offer : "",
    decline_service: editDetails?.decline_service
      ? editDetails?.decline_service
      : "",
    workingDays:
      editDetails?.working_days?.length > 0 ? editDetails?.working_days : [],
    zipCodes: editDetails?.zip_codes?.length > 0 ? editDetails?.zip_codes : [],
    operationHoursStart: editDetails?.operation_start
      ? editDetails?.operation_start
      : null,
    operationHoursEnd: editDetails?.operation_end
      ? editDetails?.operation_end
      : null,
    afetrHours: editDetails ? editDetails?.after_hours : false,
    active: editDetails ? editDetails?.active : false,
    about_us: editDetails?.about_us ? editDetails?.about_us : "",
    note: editDetails?.notes ? editDetails?.notes : "",
    // company_id: editDetails?.company_id ? editDetails?.company_id : "",
    free_service: editDetails?.free_service ? editDetails?.free_service : "",
    service_type: editDetails?.service_type ? editDetails?.service_type : "",
    service_area: editDetails?.service_area ? editDetails?.service_area : "",
    auto_reply:
      editDetails?.auto_reply === true ? editDetails?.auto_reply : false,
    after_hours_only:
      editDetails?.after_hours_only === true
        ? editDetails?.after_hours_only
        : false,
    color: editDetails?.color ? editDetails?.color : "",
    time_zone: editDetails?.time_zone ? editDetails?.time_zone : "",
    webhook_url: editDetails?.webhook_url ? editDetails?.webhook_url : "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is required"),
    phone: yup
      .string()
      .required("Phone number is required")
      .trim()
      .min(5, "Phone number must be at least 5 digits")
      .max(15, "Phone number must be at most 15 digits"),
    companyName: yup.string().required("Name is required"),
    sourceType: yup.string().required("Source Type is required"),
    offerType: yup.string().required("Offer Type is required"),
    decline_service: yup.string().required("Decline Service is required"),
    workingDays: yup
      .array()
      .of(yup.string().required("Working Days is required"))
      .min(1, "At least one Day is required"),
    zipCodes: yup
      .array()
      .of(yup.string().required("Zip code is required"))
      .min(1, "At least one Zip code is required"),
    operationHoursStart: yup.string().required("Start time is required"),
    operationHoursEnd: yup.string().required("End time is required"),
    about_us: yup.string().required("About us is required"),
    note: yup.string().required("Note is required"),
    free_service: yup.string().required("Free service is required"),
    service_type: yup.string().required("Service type is required"),
    service_area: yup.string().required("Service area is required"),
    color: yup
      .string()
      .optional()
      .matches(/^#([A-Fa-f0-9]{6})$/, "Invalid hex code"),
    time_zone: yup.string().required("Timezone is required"),
  });

  const handleSubmit = async (values) => {
    if (values?.operationHoursStart < values?.operationHoursEnd) {
      setLoading(true);
      const payload = {
        name: values?.companyName,
        type: values?.sourceType,
        type_of_offer: values?.offerType,
        operation_start: values?.operationHoursStart,
        operation_end: values?.operationHoursEnd,
        working_days: values?.workingDays,
        after_hours: values?.afetrHours,
        zip_codes: values?.zipCodes,
        active: values?.active,
        email: values?.email,
        about_us: values?.about_us,
        time_zone: values?.time_zone,
        notes: values?.note,
        same_day_appointment: true,
        phone: values?.phone,
        decline_service: values?.decline_service,
        free_service: values?.free_service,
        service_type: values?.service_type,
        service_area: values?.service_area,
        auto_reply: values?.auto_reply,
        after_hours_only: values?.after_hours_only,
        color: values?.color ? values?.color : null,
        webhook_url: values?.webhook_url,
      };
      const res = await createCompany(payload);
      if (res?.id) {
        setLoading(false);
        success(messageApi, "Company Created Successfully");
        handleClose();
        listingApi();
        setFilterData(null);
        setInitialValues();
      } else {
        setLoading(false);
        const message =
          res?.response?.data?.message ||
          res?.message ||
          res?.error ||
          "Something went wrong";
        error(messageApi, message);
      }
    } else {
      error(
        messageApi,
        "Operation Hours End time should be greater than Start time"
      );
    }
  };
  const handleUpdateData = async (values) => {
    if (values?.operationHoursStart < values?.operationHoursEnd) {
      setLoading(true);
      const payload = {
        name: values?.companyName,
        type: values?.sourceType,
        type_of_offer: values?.offerType,
        operation_start: values?.operationHoursStart,
        operation_end: values?.operationHoursEnd,
        working_days: values?.workingDays,
        after_hours: values?.afetrHours,
        zip_codes: values?.zipCodes,
        active: values?.active,
        email: values?.email,
        about_us: values?.about_us,
        time_zone: values?.time_zone,
        notes: values?.note,
        same_day_appointment: true,
        phone: values?.phone,
        decline_service: values?.decline_service,
        free_service: values?.free_service,
        service_type: values?.service_type,
        service_area: values?.service_area,
        auto_reply: values?.auto_reply,
        after_hours_only: values?.after_hours_only,
        color: values?.color ? values?.color : null,
        webhook_url: values?.webhook_url,
      };
      const res = await editCompany(editDetails?.id, payload);
      if (res?.status === 200) {
        setLoading(false);
        success(messageApi, "Company Updated Successfully");
        handleClose();
        listingApi();
        setFilterData(null);
        setInitialValues();
      } else {
        setLoading(false);
        const message =
          res?.response?.data?.message ||
          res?.message ||
          res?.error ||
          "Something went wrong";
        error(messageApi, message);
      }
    } else {
      error(
        messageApi,
        "Operation Hours End time should be greater than Start time"
      );
    }
  };

  const handlePaste = async (value, setFieldValue) => {
    navigator.permissions
      .query({ name: "clipboard-read" })
      .then((permissionStatus) => {
        if (
          permissionStatus.state === "granted" ||
          permissionStatus.state === "prompt"
        ) {
          navigator.clipboard
            .readText()
            .then((text) => {
              const clipboardTags = text?.split(",");
              const filteredArray = [...value];
              clipboardTags?.forEach((e) => {
                if (/[0-9]/i.test(e?.trim())) {
                  const number = e.replace(/\D/g, "");
                  if (filteredArray?.length > 0) {
                    if (!filteredArray.includes(number)) {
                      filteredArray.push(number);
                    }
                  } else {
                    filteredArray.push(number?.trim());
                  }
                }
              });

              setFieldValue("zipCodes", [...filteredArray]);
            })
            .catch(() => {
              alert("You don't have permission to access the clipboard");
            });
        } else {
          alert("You don't have permission to access the clipboard");
          // You don't have permission to access the clipboard
        }
      });
  };

  const handleDeselect = (deselectedTag, value, setFieldValue) => {
    const updatedTags = value.filter((tag) => tag !== deselectedTag);
    setFieldValue("zipCodes", updatedTags);
  };

  const handlePasteMobile = (event, value, setFieldValue) => {
    const pastedText = event?.clipboardData.getData("text");
    if (/^((?!chrome|android).)*safari/i.test(navigator?.userAgent)) {
      const clipboardTags = pastedText?.split(",");
      const filteredArray = [...value];
      clipboardTags?.forEach((e) => {
        if (/[0-9]/i.test(e?.trim())) {
          const number = e.replace(/\D/g, "");
          if (filteredArray?.length > 0) {
            if (!filteredArray.includes(number)) {
              filteredArray.push(number);
            }
          } else {
            filteredArray.push(number?.trim());
          }
        }
      });
      setFieldValue("zipCodes", [...filteredArray]);
    } else if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      const clipboardTags = pastedText?.split(",");
      const filteredArray = [...value];
      clipboardTags?.forEach((e) => {
        if (/[0-9]/i.test(e?.trim())) {
          const number = e.replace(/\D/g, "");
          if (filteredArray?.length > 0) {
            if (!filteredArray.includes(number)) {
              filteredArray.push(number);
            }
          } else {
            filteredArray.push(number?.trim());
          }
        }
      });
      setFieldValue("zipCodes", [...filteredArray]);
    }
  };

  const handleInputKeyDownZipCodes = (e, values, setFieldValue) => {
    if ((e?.ctrlKey || e?.metaKey) && e?.key === "v") {
      if (!/^((?!chrome|android).)*safari/i.test(navigator?.userAgent)) {
        handlePaste(values?.zipCodes, setFieldValue);
        e.target.value = "";
      }
    } else {
      if (e.key === "Enter") {
        e.preventDefault();
        const value = e.target.value.trim();
        if (/[0-9]/i.test(value)) {
          const number = value.replace(/\D/g, "");
          const filteredArray = [...(values?.zipCodes || [])];
          if (filteredArray?.length > 0) {
            if (!filteredArray.includes(number)) {
              filteredArray.push(number);
            }
          } else {
            filteredArray.push(number?.trim());
          }
          setFieldValue("zipCodes", filteredArray);
          e.target.value = "";
        } else {
          e.target.value = "";
        }
        selectRef.current.blur();
        selectRef.current.focus();
        return false;
      }
      if (
        e.key === "Backspace" &&
        e.target.value === "" &&
        values?.zipCodes.length > 0
      ) {
        const updatedTags = [...(values?.zipCodes || [])];
        updatedTags.pop();
        setFieldValue("zipCodes", updatedTags);
      }
    }
    return true;
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/[^\d]/g, "");
    const formattedPhoneNumber = phoneNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    return formattedPhoneNumber;
  };

  return (
    <div>
      <Modal
        open={open}
        onCancel={handleClose}
        centered
        footer={false}
        closeIcon={<span />}
        prefixCls="add-company"
      >
        <AddCompanyWrapper>
          <div className="title">
            <h3> {modalType === "edit" ? "Edit" : "Add"} Company </h3>
          </div>
          <div>
            <Formik
              initialValues={initialValues}
              onSubmit={modalType === "edit" ? handleUpdateData : handleSubmit}
              validationSchema={validationSchema}
              render={({ setFieldValue, values, errors, touched }) => (
                <Form>
                  <div className="content">
                    <div className="fields">
                      <label>
                        Email{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>
                      <Field
                        className="input"
                        name="email"
                        type="text"
                        placeholder="Enter Email"
                        component={InputField}
                      />
                    </div>
                    <div className="fields">
                      <label>
                        Phone number{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>
                      <Field
                        className="input"
                        name="phone"
                        value={values?.phone}
                        onChange={(e) => {
                          const formattedValue = formatPhoneNumber(
                            e.target.value
                          );
                          setFieldValue("phone", formattedValue);
                        }}
                        type="text"
                        placeholder="Enter phone number"
                        component={InputField}
                      />
                    </div>
                    <div className="fields">
                      <label>
                        Company name{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>
                      <Field
                        className="input"
                        name="companyName"
                        type="text"
                        placeholder="Enter name"
                        component={InputField}
                      />
                    </div>

                    <div className="fields">
                      <label htmlFor="free_service">
                        Free service{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>
                      <Field
                        id="free_service"
                        className="input"
                        name="free_service"
                        type="text"
                        placeholder="Enter free service"
                        component={InputField}
                      />
                    </div>
                    <div className="fields">
                      <label>
                        Service type{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>
                      <Field
                        className="input"
                        name="service_type"
                        type="text"
                        placeholder="Enter service type"
                        component={InputField}
                      />
                    </div>
                    <div className="fields">
                      <label>
                        Service area{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>
                      <Field
                        as="textarea"
                        className="textarea"
                        name="service_area"
                        placeholder="Enter service area"
                      />
                      {errors?.service_area && touched?.service_area && (
                        <ErrorMessage message={errors?.service_area} />
                      )}
                    </div>
                    <div className="fields">
                      <label>
                        Source Type{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>
                      <Select
                        prefixCls="inner-select3"
                        className="inner-select2"
                        value={values?.sourceType}
                        suffixIcon={false}
                        allowClear
                        placeholder="Select Source type"
                        onChange={(e) => setFieldValue("sourceType", e)}
                        options={SourceOption}
                      />
                      <div className="img-icon">
                        <DropDownIcon />
                      </div>
                      {errors?.sourceType && touched?.sourceType && (
                        <ErrorMessage message={errors?.sourceType} />
                      )}
                    </div>

                    <div className="fields">
                      <label>
                        Type of Offer{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>
                      <Field
                        className="input"
                        name="offerType"
                        type="text"
                        placeholder="Enter offer type"
                        component={InputField}
                      />
                    </div>
                    <div className="fields">
                      <label>
                        Decline Service{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>
                      <Field
                        className="input"
                        name="decline_service"
                        type="text"
                        placeholder="Enter decline service"
                        component={InputField}
                      />
                    </div>

                    <div className="fields">
                      <label>Webhook url</label>
                      <Field
                        className="input"
                        name="webhook_url"
                        type="text"
                        placeholder="Enter webhook url"
                        component={InputField}
                      />
                    </div>

                    <div className="fields">
                      <label>
                        Working Days{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>

                      <Select
                        mode="multiple"
                        maxTagCount={2}
                        prefixCls="inner-select3"
                        suffixIcon={false}
                        allowClear={true}
                        value={values?.workingDays}
                        className="inner-select2"
                        placeholder="Select Working days"
                        onChange={(e) => setFieldValue("workingDays", e)}
                        options={WorkingDaysOption}
                      />
                      <div className="img-icon">
                        <DropDownIcon />
                      </div>
                      {errors?.workingDays && touched?.workingDays && (
                        <ErrorMessage message={errors?.workingDays} />
                      )}
                    </div>
                    <div className="fields">
                      <label>
                        Zip codes{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>
                      <Select
                        ref={selectRef}
                        mode="tags"
                        maxTagCount={2}
                        prefixCls="inner-select3"
                        value={values?.zipCodes}
                        suffixIcon={false}
                        allowClear={true}
                        tokenSeparators={[","]}
                        // open={false`}
                        onPaste={(e) => {
                          e.preventDefault();
                          handlePasteMobile(e, values?.zipCodes, setFieldValue);
                          return false;
                        }}
                        onDeselect={(deselectedValue) =>
                          handleDeselect(
                            deselectedValue,
                            values?.zipCodes,
                            setFieldValue
                          )
                        }
                        // inputValue=""
                        onInputKeyDown={(e) =>
                          handleInputKeyDownZipCodes(e, values, setFieldValue)
                        }
                        optionLabelProp="label"
                        optionFilterProp="label"
                        showSearch
                        filterOption={(input, option) =>
                          option?.label?.indexOf(input) >= 0
                        }
                        onClear={() => setFieldValue("zipCodes", [])}
                        className="inner-select2"
                        placeholder="Enter zip codes"
                        // {...(values?.zipCodes?.length < 2 &&
                        //   dropDownVisible && {
                        //     open,
                        //   })}
                        onDropdownVisibleChange={(e) =>
                          !e ? setDropDownVisible(false) : null
                        }
                        open={values?.zipCodes?.length > 2 && dropDownVisible}
                      >
                        {values?.zipCodes?.length > 2 &&
                          values?.zipCodes?.map((tag) => (
                            <Option key={tag} value={tag} label={tag}>
                              {tag}
                            </Option>
                          ))}
                      </Select>

                      {values?.zipCodes?.length > 2 && (
                        <div
                          style={{ right: "25px" }}
                          onClick={() => setDropDownVisible(!dropDownVisible)}
                          className="img-icon"
                        >
                          <DropDownIcon />
                        </div>
                      )}

                      {errors?.zipCodes && touched?.zipCodes && (
                        <ErrorMessage message={errors?.zipCodes} />
                      )}
                    </div>
                    <div className="fields">
                      <label htmlFor="">
                        Timezone{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>

                      <Select
                        prefixCls="inner-select3"
                        className="inner-select2"
                        value={values?.time_zone ? values?.time_zone : null}
                        suffixIcon={false}
                        allowClear
                        optionLabelProp="label"
                        optionFilterProp="label"
                        showSearch
                        filterOption={(input, option) =>
                          option?.label
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        }
                        placeholder="Select timezone"
                        onChange={(value) => setFieldValue("time_zone", value)}
                        options={timezonesList}
                      />
                      <div className="img-icon">
                        <DropDownIcon />
                      </div>
                      {errors?.time_zone && touched?.time_zone && (
                        <ErrorMessage message={errors?.time_zone} />
                      )}
                    </div>
                    <div className="fields">
                      <label>
                        Operation Hours{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <TimePicker
                            value={
                              values?.operationHoursStart
                                ? dayjs(
                                    `2022-09-12 ${values?.operationHoursStart}`
                                  )
                                : null
                            }
                            placeholder="Start Time"
                            onChange={(_, time) =>
                              setFieldValue("operationHoursStart", time)
                            }
                            needConfirm={false}
                            format="HH:mm"
                            prefixCls="date-picker"
                            className="time-picker"
                          />
                          {errors?.operationHoursStart &&
                            touched?.operationHoursStart && (
                              <ErrorMessage
                                message={errors?.operationHoursStart}
                              />
                            )}
                        </div>

                        <div style={{ width: "100%" }}>
                          <TimePicker
                            value={
                              values?.operationHoursEnd
                                ? dayjs(
                                    `2022-09-12 ${values?.operationHoursEnd}`
                                  )
                                : null
                            }
                            placeholder="End Time"
                            needConfirm={false}
                            onChange={(_, time) =>
                              setFieldValue("operationHoursEnd", time)
                            }
                            prefixCls="date-picker"
                            className="time-picker"
                            format="HH:mm"
                          />
                          {errors?.operationHoursEnd &&
                            touched?.operationHoursEnd && (
                              <ErrorMessage
                                message={errors?.operationHoursEnd}
                              />
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="fields">
                      <label>
                        Select Color{" "}
                        <span style={{ color: "red", fontSize: "15px" }} />
                      </label>
                      <div className="input color-box">
                        <input
                          type="text"
                          className="input-hex"
                          autoComplete="off"
                          name="color"
                          value={values?.color}
                          placeholder="Please select color"
                          onChange={(e) =>
                            setFieldValue("color", e?.target?.value)
                          }
                        />

                        <input
                          className="color-input"
                          style={{
                            width: "80px",
                            height: "70%",
                            background: "none",
                          }}
                          name="color"
                          type="color"
                          value={values?.color}
                          // datatype="hex"
                          // runat="server"
                          onChange={(e) =>
                            setFieldValue("color", e?.target?.value)
                          }
                          placeholder="Enter Color"
                        />
                      </div>
                      {errors?.color && (
                        <ErrorMessage message={errors?.color} />
                      )}
                    </div>
                    <div className="fields">
                      <label>
                        About us{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>
                      <Field
                        as="textarea"
                        className="textarea"
                        name="about_us"
                        placeholder="Enter about us"
                      />
                      {errors?.about_us && touched?.about_us && (
                        <ErrorMessage message={errors?.about_us} />
                      )}
                    </div>
                    <div className="fields">
                      <label>
                        Note{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>
                      <Field
                        as="textarea"
                        className="textarea"
                        name="note"
                        placeholder="Enter note"
                      />
                      {errors?.note && touched?.note && (
                        <ErrorMessage message={errors?.note} />
                      )}
                    </div>

                    <div className="switch-wrap">
                      <div style={{ width: "100%" }} className="fields">
                        <label>After Hours </label>
                        <ConfigProvider
                          theme={{
                            components: {
                              Switch: {
                                colorPrimary: "#296eff",
                                handleBg: "#fff",
                                colorPrimaryHover: "#296eff",
                                colorTextQuaternary: "#2c2c2c",
                                colorTextTertiary: "#2c2c2c",
                              },
                            },
                          }}
                        >
                          <Switch
                            prefixCls="custom-switch"
                            value={values?.afetrHours}
                            onChange={(e) => setFieldValue("afetrHours", e)}
                          />
                        </ConfigProvider>
                      </div>
                      <div style={{ width: "100%" }} className="fields">
                        <label>After Hours Only</label>
                        <ConfigProvider
                          theme={{
                            components: {
                              Switch: {
                                colorPrimary: "#296eff",
                                handleBg: "#fff",
                                colorPrimaryHover: "#296eff",
                                colorTextQuaternary: "#2c2c2c",
                                colorTextTertiary: "#2c2c2c",
                              },
                            },
                          }}
                        >
                          <Switch
                            prefixCls="custom-switch"
                            value={values?.after_hours_only}
                            onChange={(e) =>
                              setFieldValue("after_hours_only", e)
                            }
                          />
                        </ConfigProvider>
                      </div>
                      <div style={{ width: "100%" }} className="fields">
                        <label>Active </label>
                        <ConfigProvider
                          theme={{
                            components: {
                              Switch: {
                                colorPrimary: "#296eff",
                                handleBg: "#fff",
                                colorPrimaryHover: "#296eff",
                                colorTextQuaternary: "#2c2c2c",
                                colorTextTertiary: "#2c2c2c",
                              },
                            },
                          }}
                        >
                          <Switch
                            prefixCls="custom-switch"
                            value={values?.active}
                            onChange={(e) => setFieldValue("active", e)}
                          />
                        </ConfigProvider>
                      </div>
                      <div style={{ width: "100%" }} className="fields">
                        <label>Auto reply</label>
                        <ConfigProvider
                          theme={{
                            components: {
                              Switch: {
                                colorPrimary: "#296eff",
                                handleBg: "#fff",
                                colorPrimaryHover: "#296eff",
                                colorTextQuaternary: "#2c2c2c",
                                colorTextTertiary: "#2c2c2c",
                              },
                            },
                          }}
                        >
                          <Switch
                            prefixCls="custom-switch"
                            value={values?.auto_reply}
                            onChange={(e) => setFieldValue("auto_reply", e)}
                          />
                        </ConfigProvider>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <button onClick={handleClose}>CANCEL</button>

                    {loading ? (
                      <button className="btn-save">Loading...</button>
                    ) : (
                      <button className="btn-save" type="submit">
                        {modalType == "edit" ? "SAVE" : "CREATE"}
                      </button>
                    )}
                  </div>
                </Form>
              )}
            />
          </div>
        </AddCompanyWrapper>
      </Modal>
    </div>
  );
}

AddCompanyModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  modalType: PropTypes.string,
  editDetails: PropTypes.object,
  messageApi: PropTypes.any,
  listingApi: PropTypes.func,
  setFilterData: PropTypes.func,
  setInitialValues: PropTypes.func,
};

const AddCompanyWrapper = styled.div`
  width: 100%;
  padding: 24px;
  max-height: 96vh;
  padding-left: 20px;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #00000045;
    border-radius: 10px;
  }

  .title {
    h3 {
      color: #2c2c2c;
      font-family: "Poppins";
      font-size: 23px;
    }
  }

  .content {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 14px;

    .fields {
      display: flex;
      flex-direction: column;
      gap: 6px;
      position: relative;

      .img-icon {
        position: absolute;
        right: 20px;
        top: 45px;
        cursor: pointer;
        ${
          "" /* svg {
          filter: ${({ themeValue }) =>
            themeValue == "day" ? "invert(0)" : "invert(1)"};
        } */
        }
      }

      label {
        color: #2c2c2c;
        font-family: "Poppins";
        font-size: 13px;
      }
      .input {
        background: transparent;
        border: 1px solid #cecece;
        border-radius: 10px;
        color: #000;
        &:focus {
          outline: none;
        }
      }
      .color-box {
        position: relative;
        height: 50px;

        .input-hex {
          width: 80%;
          height: 48px;
          background: transparent;
          border: none;
          padding-left: 15px;
          color: #000;
          font-family: "Poppins";

          &:focus {
            outline: none;
          }
        }

        .color-input {
          position: absolute;
          right: 10px;
          top: 6.5px;
        }
      }

      .textarea {
        background: transparent;
        border: 1px solid #cecece;
        border-radius: 10px;
        width: 100% !important;
        height: 64px !important;
        color: #000;
        resize: none;
        padding: 10px;
        font-family: "Poppins" !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        &::placeholder {
          color: #535e6d;
          font-family: "Poppins" !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
  .inner-select2 {
    color: #000;
    font-family: "Poppins" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    appearance: none;
    height: 50px;
    width: 100%;
    padding: 0px 0px;
    border-radius: 10px;
    border: 1px solid #cecece !important;

    .inner-select2-selection-overflow {
      margin-top: -4px !important;
      margin-left: 11px !important;
    }
  }

  .time-picker {
    color: #000;
    font-family: "Poppins" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    height: 50px;
    width: 100%;
    padding: 0px 0px;
    border-radius: 10px;
    border: 1px solid #cecece !important;
    display: flex !important;
    justify-content: space-around !important;
    background-color: transparent;
  }
  .btn-wrapper {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 26px;

    @media (max-width: 480px) {
      flex-direction: column-reverse;
      gap: 10px;
    }

    button {
      width: 100%;
      display: flex;
      height: 48px;
      padding: 21px 24px;
      justify-content: center;
      align-items: center;
      background: transparent;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid #2c2c2c;
      color: #2c2c2c;
      text-align: center;
      cursor: pointer;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: 0.2px;
    }

    .btn-save {
      background: #296eff;
      color: #fff;
      border: none;
    }
  }
  .switch-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
`;
