import { Modal } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { APP_URL } from "../../utils/AppVariables";
import { error } from "../../utils/ApiErrorMessage";
import ErrorMessage from "../../utils/ErrorMessage";

function SelectYelpLocation({ open, onClose, locationData, messageApi }) {
  const [loading, setLoading] = useState(false);
  const [errors, setError] = useState("");
  const [location, setLocation] = useState(null);

  const handleSubmit = async () => {
    if (!location) {
      setError("Please select at least one address");
    } else {
      setLoading(true);
      let res; //= await setLocationPodium(payload);
      if (res?.access_token) {
        setLoading(false);
        onClose();
        window.location.href = APP_URL;
      } else {
        const message =
          res?.response?.data?.message ||
          res?.message ||
          res?.error ||
          "Something went wrong";
        error(messageApi, message);
      }
    }
  };

  return (
    <Modal
      open={open}
      centered
      prefixCls="select-location"
      footer={false}
      closable={false}
    >
      <LocationWrapper>
        <div className="title">
          <h2>Select Location</h2>
        </div>

        <div className="content">
          {locationData?.length > 0 &&
            locationData?.map((el, idx) => (
              <label key={idx} htmlFor={el?.uid}>
                <div
                  className="fields"
                  style={{
                    borderBottom: locationData?.length - 1 == idx && "unset",
                  }}
                >
                  <div className="location">
                    <h3>{el?.name}</h3>
                    <p>{el?.address}</p>
                  </div>

                  <input
                    id={el?.uid}
                    type="radio"
                    name="location"
                    value={JSON.stringify(el)}
                    onChange={(e) => {
                      setLocation(JSON.parse(e?.target?.value));
                      setError("");
                    }}
                  />
                </div>
              </label>
            ))}

          {errors && <ErrorMessage message={errors} />}
        </div>
        <div className="btn-wrapper">
          {loading ? (
            <button className="btn-save">Loading...</button>
          ) : (
            <button className="btn-save" type="submit" onClick={handleSubmit}>
              SAVE
            </button>
          )}
        </div>
      </LocationWrapper>
    </Modal>
  );
}

SelectYelpLocation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  locationData: PropTypes.any,
  messageApi: PropTypes.any,
};

export default SelectYelpLocation;

const LocationWrapper = styled.div`
  width: 100%;
  padding: 24px;
  padding-top: 0;
  background: #00050b;
  border: 1px solid #fff;
  border-radius: 7px;
  height: 100%;
  max-height: 93vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #00000045;
    border-radius: 10px;
  }

  .title {
    width: 100%;

    padding: 12px 0;

    h2 {
      font-family: "Poppins";
      color: #fff;
      font-weight: 600;
    }
  }
  .content {
    width: 100%;

    .fields {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      border-bottom: 1px dashed #cccccc26;
      padding-bottom: 10px;
      cursor: pointer;

      input {
        accent-color: #f98229;
        width: 15px;
        cursor: pointer;
      }

      .location {
        color: #fff;
        font-family: "Poppins";

        h3 {
          font-weight: normal;
        }
      }
    }
  }

  .btn-wrapper {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 26px;

    @media (max-width: 480px) {
      flex-direction: column-reverse;
      gap: 10px;
    }

    button {
      width: 100%;
      display: flex;
      height: 48px;
      padding: 21px 24px;
      justify-content: center;
      align-items: center;
      background: #000;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid #fff;
      color: #fff;
      text-align: center;
      cursor: pointer;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: 0.2px;
    }

    .btn-save {
      background: #f98229;
      color: #fff;
      border: none;
    }
  }
`;
