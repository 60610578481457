import axios from "axios";

import { SignInAction } from "../auth/LoginSlice";
import { store } from "../store/store";

const EndPoint = process.env.REACT_APP_EXTERNAL_BASEURL;

const Api = axios.create({
  timeout: 1000000,
  baseURL: EndPoint,
});

Api.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
Api.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
Api.interceptors.request.use(
  (config) => {
    if (store.getState()?.LoginSlice?.data?.token) {
      const token = `Bearer ${store.getState()?.LoginSlice?.data?.token}`;
      config.headers = {
        Authorization: token,
      };
    } else {
      // const lang = store.getState().languageDirection.language;
    }
    return config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error)
);

// Add a response interceptor
Api.interceptors.response.use(
  (response) => {
    if (response?.status === 401) {
      store.dispatch(SignInAction(null));
    } else {
      return response;
    }
  },
  (error) => {
    if (error.response && error?.response?.status === 401) {
      store.dispatch(SignInAction(null));
    } else {
      return Promise.reject(error);
    }
  }
);
export default Api;
