import { Field, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SignInAction } from "./LoginSlice";
import InputField from "../validations/InputField";
// import { LoginRightBg } from "../utils/Images";
import { internalLogin } from "../services/Collection";
import { error } from "../utils/ApiErrorMessage";
import { success } from "../utils/ApiSuccessMessage";
import { EyeIcon, HideEyeIcon } from "../utils/SvgIcons";
import { MessageContext } from "../App";
import { LoginLogoLower, LoginLogoUpper } from "../utils/Images";

export function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const messageApi = useContext(MessageContext);
  const [passwordType, setPasswordType] = useState("password");
  const initialValues = {
    email: "",
    password: "",
  };
  const FormikFieldValues = [
    {
      label: "Email",
      name: "email",
      type: "email",
      placeholder: "Enter email",
      component: InputField,
    },
    {
      label: "Password",
      name: "password",
      type: passwordType,
      placeholder: "Enter password",
      component: InputField,
      EyeComponent: (
        <PasswordEyeWrapper
          onClick={() => {
            setPasswordType(passwordType === "password" ? "text" : "password");
          }}
        >
          {passwordType === "password" ? (
            <HideEyeIcon />
          ) : (
            <EyeIcon style={{ margin: "0px" }} />
          )}
        </PasswordEyeWrapper>
      ),
      ForgotPassword: (
        <div className="forgot-password">
          <p onClick={() => navigate("forgot")}>
            <u>Forgot Password</u>
          </p>
        </div>
      ),
    },
  ];

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    const reqPayload = {
      email: values.email.trim(),
      password: values.password.trim(),
    };
    const res = await internalLogin(reqPayload);
    if (res?.status === 200) {
      setLoading(false);
      success(messageApi, "Login Successfully");
      const data = {
        ...res?.data,
        ...res?.extraData,
        // isCompanyUser: true,
      };
      if (!data?.is_super_admin) {
        data.isCompanyUser = true;
      } else {
        data.isCompanyUser = false;
      }
      dispatch(SignInAction(data));
      if (!data?.is_super_admin) {
        navigate("/chats");
      } else {
        navigate("/dashboard");
      }
    } else {
      setLoading(false);
      let message = "Something went wrong";

      if (res?.response?.data?.message) {
        message = res.response.data.message;
      } else if (res?.message) {
        message = res.message;
      } else if (res?.error) {
        message = res.error;
      }

      error(messageApi, message);
    }
  };

  return (
    <LoginWrapper>
      <div className="flex-div">
        {/* <div className="gradient-bg">
          <img src={LoginBg} alt="" />
        </div> */}
        <div className="inner-wrap">
          <div className="main-content">
            <p className="booking-heading">
              AI Booking <span>Buddy</span>
            </p>
            <p className="discover">Discover. Book. Enjoy.</p>
            <div className="inner-part">
              <p className="sign-in">Sign in</p>
              <p className="welcome">Welcome back! Login to your Account</p>
              {/* <h1>AI Booking</h1> */}
              <div className="main-box">
                {/* <div className="logo-div">
                <img src={MainLogo} alt="" />

                <h4>Sign in your account</h4>
              </div> */}
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                  render={() => (
                    <Form>
                      <div className="content">
                        {FormikFieldValues?.map((field, idx) => (
                          <div key={idx} style={{ position: "relative" }}>
                            <label htmlFor={field?.name}>{field?.label}</label>
                            <Field
                              id={field?.name}
                              name={field?.name}
                              type={field?.type}
                              placeholder={field?.placeholder}
                              component={field?.component}
                            />
                            {field?.EyeComponent}
                            {/* {field?.ForgotPassword && field?.ForgotPassword} */}
                          </div>
                        ))}

                        {loading ? (
                          <button
                            type="submit"
                            style={{ fontFamily: "Poppins" }}
                          >
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            style={{
                              fontFamily: "Poppins",
                              userSelect: "none",
                            }}
                          >
                            Sign In
                          </button>
                        )}
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="image-box">
          <div className="upper-img">
            <img src={LoginLogoUpper} alt="login" />
          </div>
          <div className="absolute-img-content">
            <p className="experience">
              Seamless booking,
              <br /> unforgettable
              <br /> experiences
            </p>
            <p className="image-brief">
              {"we're"} dedicated to simplifying travel. With a seamless
              platform and exceptional service, {"we're"} your trusted partner
              in creating unforgettable experiences, one journey at a time
            </p>
          </div>
          <div className="lower-img">
            <img src={LoginLogoLower} alt="login" />
          </div>
        </div>
      </div>
    </LoginWrapper>
  );
}

const LoginWrapper = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  width: 100%;
  height: 100vh;
  background: #fbfcfe;
  overflow: hidden;

  .forgot-password {
    position: absolute;
    bottom: -44px;
    cursor: pointer;
    right: 5px;
    p {
      color: #f98229;
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 400 !important;
      text-align: left;

      &:hover {
        color: blue;
      }
    }
  }

  .flex-div {
    height: 100%;
    width: 100%;
    display: flex;
    @media (max-width: 950px) {
      justify-content: center;
    }
    ${
      "" /* position: relative;
    justify-content: center;
    align-items: center; */
    }

    .gradient-bg {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .inner-wrap {
      ${"" /* position: absolute; */}
      display:flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      @media (max-width: 950px) {
        width: 100%;
      }

      .main-content {
        .booking-heading {
          font-family: Poppins;
          font-weight: 700;
          font-size: 32px;

          span {
            color: #296eff;
          }
        }
        .discover {
          font-family: Poppins;
          font-weight: 500;
          font-size: 16px;
          color: #7b7f91;
          margin: 5px 0px 30px 0px;
        }
      }
      h1 {
        font-family: Poppins;
        font-size: 60px;
        font-weight: 800;
        line-height: 90px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 15px;
        color: #f98229;
      }
    }
    .image-box {
      width: 100%;
      position: relative;
      background: #1e5de2;
      height: 100%;

      .upper-img {
        width: 100%;

        img {
          margin-left: 40px;
          object-fit: contain;

          @media (max-height: 700px) {
            height: 92vh;
          }
        }
      }
      .lower-img {
        width: 100%;
        position: absolute;
        bottom: -5px;

        img {
          width: 200px;
          padding-left: 40px;
        }
      }

      @media (max-width: 950px) {
        display: none;
      }

      img {
        position: relative;
        width: 100%;
        height: 100%;
      }
      .absolute-img-content {
        position: absolute;
        z-index: 9999;
        left: 45px;
        bottom: 180px;
        width: 411px;

        @media (max-height: 750px) {
          bottom: 80px;
        }

        .experience {
          font-family: Poppins;
          font-size: 42px;
          font-weight: 700;
          line-height: 54px;
          color: #fff;
          margin-bottom: 15px;

          @media (max-width: 1070px) {
            font-size: 36px !important;
          }

          @media (max-height: 650px) {
            font-size: 32px;
            line-height: 44px;
          }
        }
        .image-brief {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
        }
      }
    }

    .inner-part {
      background: #fff;
      box-shadow: 0px 4px 64px 0px #0000000d;
      width: 388px;
      ${"" /* height:392px; */}
      border-radius: 20px;
      padding: 30px;

      @media (max-width: 500px) {
        width: 100%;
      }

      .sign-in {
        color: #242424;
        font-family: Poppins;
        font-weight: 600;
      }
      .welcome {
        color: #7b7f91;
        font-family: Poppins;
        margin-top: 5px;
      }

      .main-box {
        ${"" /* padding: 40px; */}
        padding-top:40px;
        .logo-div {
          text-align: center;
          img {
            width: 150px;
            margin-bottom: 1rem;
          }

          h4 {
            margin-bottom: 1.5rem;
            color: #000;
            font-size: 25px;
            font-family: "Poppins";
          }
        }
        .content {
          div {
            margin-bottom: 30px;
            label {
              color: #000;
              margin-bottom: 0.25rem;
              display: block;
              font-family: "Poppins";
              font-size: 16px;
              font-weight: 400 !important;
              letter-spacing: 0em;
              text-align: left;
            }
            .password-div {
              display: flex;
              position: relative;
              input {
                background-color: #f8f8f8;
                color: #000;
                position: relative;
                padding: 0rem 0.75rem;
                width: 100%;
                ${"" /* height: 41px; */}
                height: 48px;
                border-radius: 12px;

                font-family: "Poppins" !important;
                font-size: 14px;

                :focus {
                  outline: none;
                }
                ::placeholder {
                  color: rgba(0, 0, 0, 0.39) !important;
                }
              }
              svg {
                position: absolute;
                right: 12px;
                top: 20px;
                cursor: pointer;
              }
              img {
                position: absolute;
                right: 12px;
                top: 15px;
                cursor: pointer;
              }
            }
            input {
              background-color: #f8f8f8;
              color: #000;
              position: relative;
              padding: 0rem 0.75rem;
              width: 100%;
              ${"" /* height: 41px; */}
              height: 48px;
              border-radius: 12px;

              font-family: "Poppins" !important;
              font-size: 14px;

              &:focus {
                outline: none;
                border: 1px solid #000;
              }
              ::placeholder {
                color: rgba(0, 0, 0, 0.39) !important;
              }
            }
          }
          button {
            font-weight: 500;
            height: 50px;
            cursor: pointer;
            border-radius: 0.75rem;
            width: 100%;
            background-color: #296eff;
            border: 1px solid #296eff;
            font-size: 14px;
            color: #fff;
            text-align: center;
          }
          span {
            font-weight: 700;
            height: 50px;
            cursor: pointer;
            border-radius: 0.75rem;
            width: 100%;
            background-color: #363062;
            font-size: 14px;
            border: 1px solid #363062;
            color: #fff;
            text-align: center;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .inner-wrap {
      width: 95% !important;
    }
  }
`;

export const LoadingButton = styled.span`
  width: 100%;
  font-weight: 700;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.75rem;
  width: 100%;
  background-color: #363062;
  border: 1px solid #363062;
  color: #fff;
  text-align: center;
`;

export const PasswordEyeWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 43px;
  cursor: pointer;
  height: 20px;

  svg {
    top: 0px;
  }
`;
