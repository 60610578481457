import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "../../utils/CommonFunction";
import { Sort, SortAsc, SortDesc } from "../../utils/Images";
import { getBookingList } from "../../services/Collection";
import { BackSVGIcon } from "../../utils/SvgIcons";
import { Pagination, Table } from "antd";
import TableLoader from "../../components/TableLoader";
import styled from "styled-components";
import { useSelector } from "react-redux";

export const BookingForOneCompany = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [pagesize, setPageSize] = useState(10);
  const [extraData, setExtraData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [sortParam, setSortParam] = useState({
    sortType: "",
    sortBasis: "",
  });
  const userDetails = useSelector((state) => state?.LoginSlice?.data);

  const getSortParam = (sortBasis) => {
    setCurrentPage(1);
    if (sortBasis === sortParam.sortBasis) {
      setSortParam({
        sortType: sortParam.sortType === "desc" ? "asc" : "desc",
        sortBasis,
      });
    } else {
      setSortParam({
        sortType: "desc",
        sortBasis,
      });
    }
  };

  const srcSortImage = (Basis) => {
    if (Basis === sortParam.sortBasis) {
      if (sortParam.sortType === "asc") {
        return SortAsc;
      }
      return SortDesc;
    }
    return Sort;
  };

  const onChangePagination = (e, limit) => {
    setCurrentPage(e);
    setPageSize(limit);
  };

  const handleBookingList = async (search) => {
    setLoading(true);
    const id = userDetails?.company_id;
    const params = new URLSearchParams();
    params.append("page", Number(currentPage));
    params.append("page_size", Number(pagesize));
    params.append("company_id", id);
    sortParam?.sortBasis && params.append("sort_by", sortParam?.sortBasis);
    sortParam?.sortType && params.append("order_by", sortParam?.sortType);
    if (search !== null || search !== "") {
      if (search === undefined) {
        if (searchWord) params.append("search", searchWord);
      } else {
        search && params.append("search", search);
      }
    }
    const res = await getBookingList(id, params?.toString());
    if (res?.status === 200) {
      setLoading(false);
      setExtraData(res?.total_count);
      if (res?.data?.length > 0) {
        const arr = [];
        // eslint-disable-next-line array-callback-return
        res?.data?.map((el, index) => {
          const obj = {
            ...el,
            index: pagesize * (currentPage - 1) + (1 + index),
          };
          arr.push(obj);
        });
        setTableData(arr);
      } else {
        setTableData([]);
        // setExtraData(0);
      }
    } else {
      setLoading(false);
    }
  };

  const handleSearchListing = useCallback(debounce(handleBookingList), []);

  const handleSearch = (value) => {
    setSearchWord(value);
    handleSearchListing(value);
  };

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "index",
      render: (val) => <p>{val}.</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Email</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "email" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("email")}
            onClick={() => getSortParam("email")}
          />
        </TitleWrapper>
      ),
      dataIndex: "email",
    },
    {
      title: (
        <TitleWrapper>
          <span>First name</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "first_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("first_name")}
            onClick={() => getSortParam("first_name")}
          />
        </TitleWrapper>
      ),
      dataIndex: "first_name",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Last name</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "last_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("last_name")}
            onClick={() => getSortParam("last_name")}
          />
        </TitleWrapper>
      ),
      dataIndex: "last_name",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Phone</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "phone" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("phone")}
            onClick={() => getSortParam("phone")}
          />
        </TitleWrapper>
      ),
      dataIndex: "phone",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    // {
    //   title: "Action",
    //   render: (_, data) => (
    //     <PodiumStatusWrapper>
    //       <button
    //         style={{
    //           color: "#f98229",
    //           border: "1px solid #f98229",
    //           display: "flex",
    //           gap: "5px",
    //         }}
    //         className="btn-connected"
    //         onClick={() => {
    //           setEditDetails(data);
    //           setDetailModal(true);
    //         }}
    //       >
    //         Details
    //       </button>
    //     </PodiumStatusWrapper>
    //   ),
    // },
  ];

  useEffect(() => {
    handleBookingList();
  }, [currentPage, pagesize]);

  return (
    <TableWrapper>
      <div className="create-btn">
        <div className="backbtn-box">
          <span
            style={{
              cursor: "pointer",
              display: "flex",
              gap: "5px",
              fontFamily: "Poppins",
              color: "#000",
            }}
            onClick={() => navigate(-1)}
          >
            <BackSVGIcon /> Back
          </span>
        </div>
        <div>
          <form autoComplete="off" onSubmit={(e) => e?.preventDefault()}>
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => {
                e?.preventDefault();
                setCurrentPage(1);
                handleSearch(e?.target?.value);
              }}
              autoComplete="off"
            />
          </form>
        </div>
        {/* <div className="btn">
          <button
            style={{ width: "80px" }}
            // onClick={() => setFilterModal(true)}
          >
            Filters
          </button>
        </div> */}
      </div>
      <div style={{ position: "relative" }}>
        <Table
          prefixCls="campaignTable"
          columns={columns}
          dataSource={tableData}
          pagination={false}
        />
        {loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#ffffff66",
            }}
          >
            {/* Render your Lottie animation */}
            <TableLoader data={tableData} />
          </div>
        )}
      </div>
      {!loading && (
        <div style={{ marginTop: "10px" }}>
          <Pagination
            prefixCls="interaction-pagination-night"
            current={currentPage}
            onChange={onChangePagination}
            total={extraData}
            defaultPageSize={pagesize}
            showSizeChanger
          />
        </div>
      )}
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  background: #f2f5fd;
  min-height: calc(100vh - 82px);
  height: calc(100% - 82px);
  height: 100%;
  padding: 30px;
  padding-top: 0px;

  .create-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0px;
    gap: 7px;

    .backbtn-box {
      display: flex;
      align-items: center;
    }

    input {
      height: 43px;
      width: 100%;
      background: #ffff;
      border-radius: 7px;
      border: 1px solid #cecece;
      padding: 0 10px;
      color: #000;
      font-family: "Poppins", Arial, sans-serif;

      &:focus {
        outline: none;
        border: 1px solid #000;
      }
    }

    .btn {
      button {
        background-color: #343434;
        color: #fff;
        font-family: "Poppins";
        border-radius: 7px;
        font-weight: 600;
        font-size: 13px;
        border: none;
        width: 100px;
        height: 43px;
        cursor: pointer;
      }
    }
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 10px;

  img {
    cursor: pointer;
    height: 20px;
  }
`;
