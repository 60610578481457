import CompanyUserList from "../module/CompanyUsers/CompanyUserList";
// import Podium from "../module/Podium/Podium";
import { DataTable } from "../module/Table/DataTable";
import AiBooking from "../module/aiBookingStats/AiBooking";
import BookingList from "../module/booking/BookingList";
import ConnectCrm from "../module/connectcrm/ConnectCrm";
import ConnectedCrmList from "../module/connectcrm/ConnectedCrmList";
import Conversation from "../module/conversations/Conversation";
import { LsaTable } from "../module/lsa/LsaTable";
import { SmsTable } from "../module/sms/SmsTable";
import { YelpTable } from "../module/yelp/YelpTable";

export const PrivatePaths = [
  { path: "/dashboard", component: <DataTable /> },
  { path: "/podium/oauth/callback", component: <DataTable /> },
  { path: "/yelp", component: <DataTable /> },
  // { path: "/podium", component: <Podium /> },
  { path: "/company/:id/crm", component: <ConnectedCrmList /> },
  { path: "/company/:id/add-crm", component: <ConnectCrm /> },
  { path: "/company/:id/edit-crm", component: <ConnectCrm /> },
  { path: "/company/:id/bookings", component: <BookingList /> },
  { path: "/yelps", component: <YelpTable /> },
  { path: "/lsa", component: <LsaTable /> },
  { path: "/sms", component: <SmsTable /> },
  { path: "/company-users", component: <CompanyUserList /> },
  { path: "/chats/:company_id?", component: <Conversation /> },
  { path: "/ai-booking-stats", component: <AiBooking /> },
];
