/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Pagination, Table, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Sort, SortAsc, SortDesc } from "../../utils/Images";
import TableLoader from "../../components/TableLoader";
import {
  deleteCRMForCompany,
  listConnectedCRM,
} from "../../services/Collection";
import { DeleteIcon, EditIcon } from "../../utils/SvgIcons";
import { DeleteModal } from "../../components/DeleteModal";
import { success } from "../../utils/ApiSuccessMessage";
import { error } from "../../utils/ApiErrorMessage";
import { debounce } from "../../utils/CommonFunction";
import { ArrivalWindowSettingData } from "./ArrivalWindowSettingData";

const transformData = (scheduleData) => {
  return Object.keys(scheduleData).map((day, index) => {
    const time = scheduleData[day][0];
    const count = Object.keys(scheduleData)?.length - 1;
    return {
      key: index,
      content: `${day}, ${time} ${count > 0 ? "+" : ""}  ${count > 0 ? count : ""}`,
    };
  });
};

function ConnectedCrmList() {
  const [pagesize, setPageSize] = useState(10);
  const [extraData, setExtraData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortParam, setSortParam] = useState({
    sortType: "",
    sortBasis: "",
  });
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [editDetails, setEditDetails] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [searchWord, setSearchWord] = useState("");
  const [duplicateData, setDuplicateData] = useState(null);
  const [arrivalDataModal, setArrivalDataModal] = useState(false);
  const [arrivalDetails, setArrivalDetails] = useState(null);

  const onChangePagination = (e, limit) => {
    setCurrentPage(e);
    setPageSize(limit);
  };

  const getSortParam = (sortBasis) => {
    setCurrentPage(1);
    if (sortBasis === sortParam.sortBasis) {
      setSortParam({
        sortType: sortParam.sortType === "desc" ? "asc" : "desc",
        sortBasis,
      });
    } else {
      setSortParam({
        sortType: "desc",
        sortBasis,
      });
    }
  };

  const srcSortImage = (Basis) => {
    if (Basis === sortParam.sortBasis) {
      if (sortParam.sortType === "asc") {
        return SortAsc;
      }
      return SortDesc;
    }
    return Sort;
  };

  const handleListing = async (search) => {
    setLoading(true);
    const param = new URLSearchParams();
    sortParam?.sortBasis && param.append("sort_by", sortParam?.sortBasis);
    sortParam?.sortType && param.append("order_by", sortParam?.sortType);
    pagesize && param.append("page_size", Number(pagesize));
    currentPage && param.append("page", Number(currentPage));
    if (search !== null || search !== "") {
      if (search === undefined) {
        if (searchWord) param.append("search", searchWord);
      } else {
        search && param.append("search", search);
      }
    }
    const res = await listConnectedCRM(location?.state?.id, param?.toString());
    if (res?.status === 200) {
      setLoading(false);
      const arr = [];
      res?.data?.map((el, index) => {
        const obj = {
          ...el,
          index: pagesize * (currentPage - 1) + (1 + index),
        };
        arr.push(obj);
      });
      setTableData(arr);
      setExtraData(res?.total_count);
    } else {
      setLoading(false);
    }
  };

  const handleConnectCrm = (data, duplicate) => {
    if (duplicate) {
      navigate(`/company/${data?.company_id}/add-crm`, { state: data });
    } else {
      navigate(`/company/${data?.id}/add-crm`, { state: data });
    }
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    const res = await deleteCRMForCompany(editDetails?.id);
    if (res?.status == 200) {
      success(messageApi, "CRM Deleted Successfully");
      setDeleteLoading(false);
      setDeleteModal(false);
      setEditDetails(null);
      setDuplicateData(null);

      if (currentPage == 1) {
        handleListing();
      } else {
        setCurrentPage(1);
      }
    } else {
      setDeleteLoading(false);
      const message =
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong";
      error(messageApi, message);
    }
  };

  const handleEditCRM = (data) => {
    navigate(`/company/${location?.state?.id}/edit-crm`, { state: data });
  };

  const handleSearchListing = useCallback(debounce(handleListing), []);

  const handleSearch = (value) => {
    setSearchWord(value);
    handleSearchListing(value);
  };

  useEffect(() => {
    handleListing();
  }, [currentPage, pagesize, sortParam]);

  const columns = [
    {
      title: "",
      render: (_, data) => (
        <CheckBoxWrapper>
          <label className="container-checkbox">
            <input
              type="radio"
              name="duplicate"
              checked={data?.index == duplicateData?.index}
              value={data?.index}
              onClick={() =>
                duplicateData?.index == data?.index
                  ? setDuplicateData(null)
                  : setDuplicateData({ ...data, isDuplicate: true })
              }
            />
            <span className="checkmark" />
          </label>
          {/* <p className="btn-inner-text">Credit Card</p> */}
        </CheckBoxWrapper>
      ),
    },
    {
      title: "Sr.No",
      dataIndex: "index",
      render: (val) => <p>{val}.</p>,
    },
    // {
    //   title: (
    //     <TitleWrapper>
    //       <span>Name</span>
    //       <img
    //         style={{
    //           filter: sortParam?.sortBasis != "name" && "invert(1)",
    //         }}
    //         alt=""
    //         className="sortIcons"
    //         src={srcSortImage("name")}
    //         onClick={() => getSortParam("name")}
    //       />
    //     </TitleWrapper>
    //   ),
    //   dataIndex: "name",
    // },
    {
      title: (
        <TitleWrapper>
          <span>Business Unit Name</span>
          <img
            style={{
              filter:
                sortParam?.sortBasis != "business_unit_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("business_unit_name")}
            onClick={() => getSortParam("business_unit_name")}
          />
        </TitleWrapper>
      ),
      dataIndex: "business_unit_name",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Business Unit ID</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "business_unit_id" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("business_unit_id")}
            onClick={() => getSortParam("business_unit_id")}
          />
        </TitleWrapper>
      ),
      dataIndex: "business_unit_id",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Campaign name</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "campaign_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("campaign_name")}
            onClick={() => getSortParam("campaign_name")}
          />
        </TitleWrapper>
      ),
      dataIndex: "campaign_name",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Campaign id</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "campaign_id" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("campaign_id")}
            onClick={() => getSortParam("campaign_id")}
          />
        </TitleWrapper>
      ),
      dataIndex: "campaign_id",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Booking provider</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "booking_provider" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("booking_provider")}
            onClick={() => getSortParam("booking_provider")}
          />
        </TitleWrapper>
      ),
      dataIndex: "booking_provider",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Integration type</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "integration_type" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("integration_type")}
            onClick={() => getSortParam("integration_type")}
          />
        </TitleWrapper>
      ),
      dataIndex: "integration_type",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Job type name</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "job_type_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("job_type_name")}
            onClick={() => getSortParam("job_type_name")}
          />
        </TitleWrapper>
      ),
      dataIndex: "job_type_name",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Job type id</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "job_type_id" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("job_type_id")}
            onClick={() => getSortParam("job_type_id")}
          />
        </TitleWrapper>
      ),
      dataIndex: "job_type_id",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Priority</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "priority" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("priority")}
            onClick={() => getSortParam("priority")}
          />
        </TitleWrapper>
      ),
      dataIndex: "priority",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Tenant id</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "tenant_id" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("tenant_id")}
            onClick={() => getSortParam("tenant_id")}
          />
        </TitleWrapper>
      ),
      dataIndex: "tenant_id",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>App key</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "app_key" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("app_key")}
            onClick={() => getSortParam("app_key")}
          />
        </TitleWrapper>
      ),
      dataIndex: "app_key",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Technician name</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "technician_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("technician_name")}
            onClick={() => getSortParam("technician_name")}
          />
        </TitleWrapper>
      ),
      dataIndex: "technician_name",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Technician id</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "technician_id" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("technician_id")}
            onClick={() => getSortParam("technician_id")}
          />
        </TitleWrapper>
      ),
      dataIndex: "technician_id",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Tag name</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "tag_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("tag_name")}
            onClick={() => getSortParam("tag_name")}
          />
        </TitleWrapper>
      ),
      dataIndex: "tag_name",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Tag id</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "tag_id" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("tag_id")}
            onClick={() => getSortParam("tag_id")}
          />
        </TitleWrapper>
      ),
      dataIndex: "tag_id",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>First appt date</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "first_appt_date" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("first_appt_date")}
            onClick={() => getSortParam("first_appt_date")}
          /> */}
        </TitleWrapper>
      ),
      dataIndex: "first_appt_date",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>First appt time</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "first_appt_time" && "invert(1)",
            }}
            className="sortIcons"
            src={srcSortImage("first_appt_time")}
            alt=""
            onClick={() => getSortParam("first_appt_time")}
          /> */}
        </TitleWrapper>
      ),
      dataIndex: "first_appt_time",
      render: (val) => (
        <p>{val ? moment(val, "HH:mm").format("HH:mm") : "N/A"} </p>
      ),
    },
    {
      title: (
        <TitleWrapper>
          <span>City</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "first_appt_time" && "invert(1)",
            }}
            className="sortIcons"
            src={srcSortImage("first_appt_time")}
            alt=""
            onClick={() => getSortParam("first_appt_time")}
          /> */}
        </TitleWrapper>
      ),
      dataIndex: "city",
      render: (val) => <p>{val ? val : "N/A"} </p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>State</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "first_appt_time" && "invert(1)",
            }}
            className="sortIcons"
            src={srcSortImage("first_appt_time")}
            alt=""
            onClick={() => getSortParam("first_appt_time")}
          /> */}
        </TitleWrapper>
      ),
      dataIndex: "state",
      render: (val) => <p>{val ? val : "N/A"} </p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Country</span>
        </TitleWrapper>
      ),
      dataIndex: "country",
      render: (val) => <p>{val ? val : "N/A"} </p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Assign Tech</span>
        </TitleWrapper>
      ),
      dataIndex: "assign_tech",
      render: (val) => <p>{val ? val : "N/A"} </p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Arrival Window Settings</span>
        </TitleWrapper>
      ),
      dataIndex: "arrival_window_settings",
      render: (val) => (
        <p
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (val != null) {
              if (Object.keys(val).length > 0) {
                setArrivalDetails(val);
                setArrivalDataModal(true);
              }
            }
          }}
        >
          {val ? transformData(val)?.[0]?.content : "N/A"}
        </p>
      ),
    },
    {
      title: (
        <TitleWrapper>
          <span>Arrival Windows</span>
        </TitleWrapper>
      ),
      dataIndex: "arrival_windows",
      render: (val) => (
        <div>
          {val?.length > 0 ? (
            val?.map((el, idx) => (
              <p key={idx} style={{ whiteSpace: "nowrap" }}>
                {el && el}
              </p>
            ))
          ) : (
            <p>N/A</p>
          )}
        </div>
      ),
    },

    {
      title: "Actions",
      // dataIndex: "active_status",
      fixed: "right",
      width: 150,
      render: (_, data) => (
        <ActionButtons>
          <div
            className="edit-icon"
            onClick={() => {
              handleEditCRM(data);
            }}
          >
            <EditIcon />
          </div>
          <div
            className="delete-icon"
            onClick={() => {
              setDeleteModal(true);
              setEditDetails(data);
            }}
          >
            <DeleteIcon />
          </div>
        </ActionButtons>
      ),
    },
  ];

  return (
    <TableWrapper>
      {contextHolder}
      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          handleClose={() => setDeleteModal(false)}
          title={`Are you sure you want to Delete this CRM for ${location?.state?.name} ?`}
          description="All your process and data will be saved."
          isLoading={deleteLoading}
          handleSubmit={handleDelete}
        />
      )}

      {arrivalDataModal && (
        <ArrivalWindowSettingData
          isVisible={arrivalDataModal}
          onClose={() => setArrivalDataModal(false)}
          scheduleData={arrivalDetails}
        />
      )}

      <div className="create-btn">
        {/* <div>
          <span
            style={{
              cursor: "pointer",
              display: "flex",
              gap: "5px",
              fontFamily: "Poppins",
              color: "white",
            }}
            onClick={() => navigate(-1)}
          >
            <BackSVGIcon /> Back
          </span>
        </div> */}
        <div className="title">
          <h2 style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
            Company: {location?.state?.name}
          </h2>
        </div>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <div>
            <form autoComplete="off" onSubmit={(e) => e?.preventDefault()}>
              <input
                type="text"
                name="search"
                value={searchWord}
                placeholder="Search"
                onChange={(e) => {
                  // e?.preventDefault();
                  setCurrentPage(1);
                  handleSearch(e?.target?.value);
                }}
              />
            </form>
          </div>
          <div className="btn">
            <button
              style={{ width: "80px" }}
              onClick={() => handleConnectCrm(location?.state, false)}
            >
              ADD
            </button>
            {duplicateData && (
              <button
                style={{ width: "180px" }}
                onClick={() => handleConnectCrm(duplicateData, true)}
              >
                Create as duplicate
              </button>
            )}
          </div>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        {loading ? (
          <div
            style={{
              position: "relative",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              minHeight: "60vh",
              borderRadius: "8px",
              backgroundColor: "#ffffff66",
            }}
          >
            {/* Render Lottie animation */}
            <TableLoader data={[]} />
          </div>
        ) : (
          <Table
            prefixCls="campaignTable"
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
        )}
      </div>
      {!loading && (
        <div style={{ marginTop: "10px" }}>
          <Pagination
            prefixCls="interaction-pagination-night"
            current={currentPage}
            onChange={onChangePagination}
            total={extraData}
            defaultPageSize={pagesize}
            showSizeChanger
          />
        </div>
      )}
    </TableWrapper>
  );
}

export default ConnectedCrmList;

const TableWrapper = styled.div`
  background: #f2f5fd;
  ${"" /* width: 100%; */}
  min-height: calc(100vh - 82px);
  height: calc(100% - 82px);
  height: 100%;
  padding: 30px;
  padding-top: 0px;

  .create-btn {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    ${"" /* margin-bottom: 10px; */}
    gap: 7px;

    .title {
      h2 {
        color: #000;
        font-size: 22px;
        font-family: "Poppins";
      }
    }

    input {
      height: 43px;
      width: 100%;
      background: #ffff;
      border-radius: 7px;
      border: 1px solid #cecece;
      padding: 0 10px;
      color: #000;
      font-family: "Poppins", Arial, sans-serif;

      &:focus {
        outline: none;
        border: 1px solid #000;
      }
    }

    .btn {
      display: flex;
      gap: 10px;
      button {
        background-color: #296eff;
        color: #fff;
        font-family: "Poppins", Arial, sans-serif;
        border-radius: 7px;
        font-weight: 500;
        font-size: 13px;
        border: none;
        padding: 0 10px;
        height: 43px;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  gap: 10px;

  img {
    cursor: pointer;
    height: 20px;
  }
`;

const ActionButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 6px;

  .edit-icon {
    background: #f98229;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    display: grid;
    cursor: pointer;
    place-items: center;
  }
  .delete-icon {
    background: red;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    display: grid;
    cursor: pointer;
    place-items: center;
  }
`;

const CheckBoxWrapper = styled.div`
  ${"" /* position: absolute; */}
  ${"" /* left: 20px; */}
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  .container-checkbox {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 24px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    input:checked ~ .checkmark {
      background-color: #2c2c2c;
      border-style: none;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: transparent;
      border: 1.6px solid #fff;
      border-radius: 50%;
      border: 1px solid #424b57;
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
    .checkmark:after {
      left: 9.5px;
      top: 6px;
      width: 4px;
      height: 9px;
      border: 2.375px solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`;
