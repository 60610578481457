/* eslint-disable eqeqeq */
import { Modal } from "antd";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import InputField from "../validations/InputField";
import { EyeIcon, HideEyeIcon } from "../utils/SvgIcons";
import { changePassword } from "../services/Collection";
import { success } from "../utils/ApiSuccessMessage";
import { error } from "../utils/ApiErrorMessage";

function ChangePasswordModal({ open, handleClose, messageApi }) {
  const [passwordType, setPasswordType] = useState("password");
  const [confirmpasswordType, setConfirmPasswordType] = useState("password");
  const [retypePasswordType, setRetypePasswordType] = useState("password");
  const userDetails = useSelector((state) => state?.LoginSlice?.data);

  const [loading, setLoading] = useState(false);
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const validationSchema = yup.object().shape({
    oldPassword: yup.string().required("Old Password is required").min(3),
    newPassword: yup
      .string()
      .required("New Password is required")
      .notOneOf(
        [yup.ref("oldPassword")],
        "New Password must be different from the previous one"
      ),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf(
        [yup.ref("newPassword")],
        "Password should be same as new password"
      ),
  });
  const FormikFieldValues = [
    {
      label: "Old Password",
      name: "oldPassword",
      type: passwordType,
      placeholder: "Enter old password",
      component: InputField,
      autoComplete: "off",
      EyeComponent: (
        <PasswordEyeWrapper
          onClick={() =>
            setPasswordType(passwordType === "password" ? "text" : "password")
          }
        >
          {passwordType == "password" ? (
            <HideEyeIcon />
          ) : (
            <EyeIcon style={{ margin: "0px" }} />
          )}
        </PasswordEyeWrapper>
      ),
    },
    {
      label: "New Password",
      name: "newPassword",
      type: confirmpasswordType,
      placeholder: "Enter new password",
      component: InputField,
      autoComplete: "off",
      EyeComponent: (
        <PasswordEyeWrapper
          onClick={() =>
            setConfirmPasswordType(
              confirmpasswordType === "password" ? "text" : "password"
            )
          }
        >
          {confirmpasswordType == "password" ? (
            <HideEyeIcon />
          ) : (
            <EyeIcon style={{ margin: "0px" }} />
          )}
        </PasswordEyeWrapper>
      ),
    },
    {
      label: "Confirm New Password",
      name: "confirmPassword",
      type: retypePasswordType,
      placeholder: "Enter confirm password",
      component: InputField,
      autoComplete: "off",
      EyeComponent: (
        <PasswordEyeWrapper
          onClick={() =>
            setRetypePasswordType(
              retypePasswordType === "password" ? "text" : "password"
            )
          }
        >
          {retypePasswordType == "password" ? (
            <HideEyeIcon />
          ) : (
            <EyeIcon style={{ margin: "0px" }} />
          )}
        </PasswordEyeWrapper>
      ),
    },
  ];

  const handleSubmit = async (value) => {
    setLoading(true);

    const obj = {
      ...value,
      userId: userDetails?._id,
    };
    delete obj?.confirmPassword;
    const res = await changePassword(obj);
    if (res?.status == 200) {
      handleClose();
      setLoading(false);
      success(messageApi, "Password Changes Successfully");
    } else {
      setLoading(false);
      const message =
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong";
      error(messageApi, message);
    }
  };

  return (
    <Modal
      prefixCls="changePasswordModal"
      open={open}
      onCancel={handleClose}
      footer={false}
      closeIcon={null}
      centered
    >
      <ChangePasswordModalBox>
        <div className="title">
          <h3>Change Password</h3>
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          render={() => (
            <Form autoComplete="off">
              <div className="content">
                {FormikFieldValues?.map((field, index) => (
                  <div
                    key={index}
                    style={{ position: "relative" }}
                    className="fields"
                  >
                    <label>{field?.label}</label>
                    <Field
                      name={field?.name}
                      type={field?.type}
                      placeholder={field?.placeholder}
                      component={field?.component}
                      className="input"
                      autoComplete={field?.autoComplete}
                    />
                    {field?.EyeComponent}
                    {field?.ForgotPassword && field?.ForgotPassword}
                  </div>
                ))}
                <div className="btn-wrapper">
                  <button onClick={handleClose}>CANCEL</button>
                  {loading ? (
                    <button className="btn-save">Loading...</button>
                  ) : (
                    <button className="btn-save" type="submit">
                      SAVE
                    </button>
                  )}
                </div>
              </div>
            </Form>
          )}
        />
      </ChangePasswordModalBox>
    </Modal>
  );
}

ChangePasswordModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  messageApi: PropTypes.any,
};

export default ChangePasswordModal;

const ChangePasswordModalBox = styled.div`
  width: 100%;
  background: #00050b;
  padding: 20px;
  min-height: 200px;
  border: 1px solid #cecece;
  border-radius: 7px;

  .title {
    width: 100%;

    h3 {
      font-family: "Poppins";
      color: #fff;
      font-size: 25px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-top: 15px;

    .btn-wrapper {
      width: 100%;
      display: flex;
      gap: 20px;
      margin-top: 26px;

      @media (max-width: 480px) {
        flex-direction: column-reverse;
        gap: 10px;
      }

      button {
        width: 100%;
        display: flex;
        height: 48px;
        padding: 21px 24px;
        justify-content: center;
        align-items: center;
        background: transparent;
        gap: 8px;
        border-radius: 10px;
        border: 1px solid #fff;
        color: #fff;
        text-align: center;
        cursor: pointer;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        letter-spacing: 0.2px;
      }

      .btn-save {
        background: #f98229;
        color: #fff;
        border: none;
      }
    }
  }

  .fields {
    display: flex;
    flex-direction: column;
    gap: 6px;
    position: relative;

    .img-icon {
      position: absolute;
      right: 20px;
      top: 45px;
      cursor: pointer;
      ${
        "" /* svg {
        filter: ${({ themeValue }) =>
          themeValue == "day" ? "invert(0)" : "invert(1)"};
      } */
      }
    }

    label {
      color: #fff;
      font-family: "Poppins";
      font-size: 13px;
    }
    .input {
      background: transparent;
      border: 1px solid #cecece;
      border-radius: 10px;
      color: #fff;
      &:focus {
        outline: none;
      }
    }

    .textarea {
      background: #000;
      border: 1px solid #cecece;
      border-radius: 10px;
      width: 100% !important;
      height: 64px !important;
      color: #fff;
      resize: none;
      padding: 10px;
      font-family: "Poppins" !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;

      &::placeholder {
        color: #535e6d;
        font-family: "Poppins" !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }

      &:focus {
        outline: none;
      }
    }
  }
`;
const PasswordEyeWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 42px;
  cursor: pointer;
  height: 20px;

  svg {
    top: 0px;
  }
`;
