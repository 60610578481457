import axios from "axios";

import { store } from "../../store/store";
import { SignInAction } from "../../auth/LoginSlice";

// eslint-disable-next-line no-undef
const EndPoint = process.env.REACT_APP_BASEURL;

const Api = axios.create({
  timeout: 1000000,
  baseURL: EndPoint,
});
Api.defaults.baseURL = EndPoint;
Api.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
Api.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
Api.interceptors.request.use(
  (config) => {
    if (store.getState()?.LoginSlice?.data?.localToken) {
      const token = `Bearer ${store.getState()?.LoginSlice?.data?.localToken}`;
      config.headers = {
        Authorization: token,
      };
    } else {
      console.log(store);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
Api.interceptors.response.use(
  (response) => {
    if (response.data.status === 401) {
      store.dispatch(SignInAction(null));
    } else {
      return response;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default Api;
