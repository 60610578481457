import { Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { AddCompanyWrapper } from "./YelpConnectModal";
import { DropDownIcon } from "../utils/SvgIcons";
import ErrorMessage from "../utils/ErrorMessage";
import { getCompanyListing } from "../services/Collection";
import { updateSelectedChat } from "../store/MessageSlice";

function YelpFilterModal({
  open,
  handleClose,
  editDetails,
  setFilterData,
  setFilterPayload,
  setCurrentPage,
}) {
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [scrollPage, setScrollPage] = useState(1);
  const dispatch = useDispatch();

  const initialValues = editDetails || {};

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
      setScrollPage(scrollPage + 1);
    }
  };

  const handleCompanyList = async () => {
    setLoading(true);
    const params = new URLSearchParams();
    params.append("page", scrollPage);
    params.append("page_size", 100);

    const res = await getCompanyListing(params);
    if (res?.data?.length > 0) {
      const array = res?.data?.map((el) => {
        const obj = {
          label: el?.name,
          value: el?.id,
        };
        return obj;
      });
      if (scrollPage === 1) {
        setCompanyList(array);
      } else {
        setCompanyList([...companyList, ...array]);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleSubmit = (values) => {
    setFilterData(values);
    dispatch(updateSelectedChat(null));
    const filterArr = [];
    for (const key in values) {
      // eslint-disable-next-line no-prototype-builtins
      if (values.hasOwnProperty(key)) {
        const requestObject = {
          field: key,
          value: values[key]?.value,
        };
        if (values[key]) {
          filterArr.push(requestObject);
        }
      }
    }
    setFilterPayload(filterArr);
    handleClose();
    setCurrentPage(1);
  };

  useEffect(() => {
    handleCompanyList();
  }, [scrollPage]);

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      width={520}
      footer={false}
      closeIcon={false}
      prefixCls="add-company"
    >
      <AddCompanyWrapper>
        <div className="title">
          <h3>Filters</h3>
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            render={({ values, setFieldValue, errors, touched }) => (
              <Form>
                <div className="content">
                  <div className="fields">
                    <label>
                      Select Company name{" "}
                      <span style={{ color: "red", fontSize: "15px" }}>*</span>
                    </label>
                    <Select
                      prefixCls="inner-select3"
                      className="inner-select2"
                      value={values?.company_id}
                      loading={loading}
                      onPopupScroll={handleScroll}
                      showSearch
                      allowClear
                      suffixIcon={false}
                      placeholder="Select Company"
                      onChange={(e, data) => setFieldValue("company_id", data)}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      options={companyList}
                    />
                    {loading ? (
                      <div className="img-icon">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 14,
                              }}
                              spin
                              color="red"
                            />
                          }
                        />
                      </div>
                    ) : (
                      <div className="img-icon">
                        <DropDownIcon />
                      </div>
                    )}
                    {errors?.type && touched?.type && (
                      <ErrorMessage message={errors?.type} />
                    )}
                    {/* <Field
                      type="text"
                      name="company_id"
                      className="input"
                      placeholder="Enter business id"
                      component={InputField}
                    /> */}
                  </div>
                </div>
                <div className="btn-wrapper">
                  <button onClick={handleClose}>CANCEL</button>

                  <button className="btn-save" type="submit">
                    APPLY
                  </button>
                </div>
              </Form>
            )}
          />
        </div>
      </AddCompanyWrapper>
    </Modal>
  );
}

export default YelpFilterModal;

YelpFilterModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  editDetails: PropTypes.object,
  setFilterData: PropTypes.func,
  setFilterPayload: PropTypes.any,
  setCurrentPage: PropTypes.any,
};
