import React, { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
import { Pagination, Table } from "antd";
import { useSelector } from "react-redux";
import TableLoader from "../../components/TableLoader";
import { getUsersList, updateUserStatus } from "../../services/Collection";
import { SwitchField } from "../../components/SwitchField";
import { debounce } from "../../utils/CommonFunction";
import YelpFilterModal from "../../modals/YelpFilterModal";

export function LsaTable() {
  const [tableData, setTableData] = useState([]);
  const [sortParam] = useState({
    sortType: "",
    sortBasis: "",
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [extraData, setExtraData] = useState(0);
  const [loader, setLoader] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [switchLoading, setSwitchLoading] = useState(null);
  const [filterModal, setFilterModal] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [filterPayload, setFilterPayload] = useState([]);
  const userDetails = useSelector((state) => state?.LoginSlice?.data);

  //   const getSortParam = (sortBasis) => {
  //     if (sortParam?.sortBasis === sortBasis) {
  //       const filterObject = {
  //         sortBasis,
  //         sortType: sortParam?.sortType === "desc" ? "asc" : "desc",
  //       };
  //       setSortParam(filterObject);
  //     } else {
  //       const filterObject = {
  //         sortBasis,
  //         sortType: "desc",
  //       };
  //       setSortParam(filterObject);
  //     }
  //   };

  const onChangePagination = (e, limit) => {
    setPage(e);
    setPageSize(limit);
  };

  const handleUpdateYelpUser = async (id, requestPayload, name) => {
    setSwitchLoading({ id, name });
    const index = tableData.findIndex((obj) => obj.id === id);
    tableData[index] = { ...tableData[index], ...requestPayload };

    const res = await updateUserStatus("lsa", id, requestPayload);
    if (res?.id) {
      const tempData = [...(tableData ?? [])];
      const index = tempData.findIndex((obj) => obj.id === id);
      tempData[index] = {
        ...tempData[index],
        ...res,
        index: pageSize * (page - 1) + (1 + index),
      };
      setTableData(tempData);
      setSwitchLoading(null);
    } else {
      setSwitchLoading(null);
    }
  };

  const handleChangeSwitch = (val, name, data) => {
    const obj = {
      [name]: val,
    };
    handleUpdateYelpUser(data?.id, obj, name);
  };

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "index",
      render: (val) => <p>{val}.</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Company DB Name</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("name", sortParam)}
            onClick={() => getSortParam("name")}
          /> */}
        </TitleWrapper>
      ),
      dataIndex: "company_db_name",
    },
    // {
    //   title: (
    //     <TitleWrapper>
    //       <span>Email</span>
    //       <img
    //         style={{
    //           filter: sortParam?.sortBasis != "email" && "invert(1)",
    //         }}
    //         alt=""
    //         className="sortIcons"
    //         src={srcSortImage("email", sortParam)}
    //         onClick={() => getSortParam("email")}
    //       />
    //     </TitleWrapper>
    //   ),
    //   dataIndex: "email",
    //   render: (val) => (
    //     <div>
    //       <Tooltip prefixCls="tooltip-box" title={val}>
    //         <p
    //           style={{
    //             maxWidth: "220px",
    //             overflow: "hidden",
    //             whiteSpace: "nowrap",
    //             textOverflow: "ellipsis",
    //           }}
    //         >
    //           {val}
    //         </p>
    //       </Tooltip>
    //     </div>
    //   ),
    // },
    {
      title: (
        <TitleWrapper>
          <span>Lead Phone</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "business_id" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("business_id", sortParam)}
            onClick={() => getSortParam("business_id")}
          /> */}
        </TitleWrapper>
      ),
      dataIndex: "lead_phone",
    },
    // {
    //   title: (
    //     <TitleWrapper>
    //       <span>Id</span>
    //       <img
    //         style={{
    //           filter: sortParam?.sortBasis != "lead_id" && "invert(1)",
    //         }}
    //         alt=""
    //         className="sortIcons"
    //         src={srcSortImage("lead_id", sortParam)}
    //         onClick={() => getSortParam("lead_id")}
    //       />
    //     </TitleWrapper>
    //   ),
    //   dataIndex: "lead_id",
    // },
    {
      title: (
        <TitleWrapper>
          <span>Stop Status</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis != "stop_status" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("stop_status", sortParam)}
            onClick={() => getSortParam("stop_status")}
          /> */}
        </TitleWrapper>
      ),
      dataIndex: "stop_status",
      render: (val, data) => (
        <div>
          <SwitchField
            value={val}
            data={data}
            handleChange={handleChangeSwitch}
            switchLoading={switchLoading}
            name="stop_status"
          />
        </div>
      ),
    },
    {
      title: (
        <TitleWrapper>
          <span>Stop Followup</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis !== "stop_followup" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("stop_followup", sortParam)}
            onClick={() => getSortParam("stop_followup")}
          /> */}
        </TitleWrapper>
      ),
      dataIndex: "stop_followup",
      render: (val, data) => (
        <div>
          <SwitchField
            value={val}
            data={data}
            handleChange={handleChangeSwitch}
            switchLoading={switchLoading}
            name="stop_followup"
          />
        </div>
      ),
    },
  ];

  const handleYelpListing = async (search) => {
    setLoader(true);
    const params = new URLSearchParams();
    params.append("page_size", pageSize);
    params.append("page", page);
    // params.append("user_type", "lsa");

    if (search !== null || search != "") {
      if (search === undefined) {
        if (searchWord) params.append("search", searchWord);
      } else {
        search && params.append("search", search);
      }
    }
    // Apply filter for company users
    let companyUserPayload = [];
    if (userDetails?.isCompanyUser) {
      companyUserPayload = [
        { field: "company_id", value: userDetails?.company_id },
      ];
    }
    const res = await getUsersList(
      "lsa",
      params,
      companyUserPayload?.length > 0
        ? companyUserPayload
        : filterPayload?.length > 0 && filterPayload
    );
    setExtraData(res?.total_count);
    if (res?.status === 200) {
      const array = res?.data?.map((el, idx) => ({
        ...el,
        index: pageSize * (page - 1) + (1 + idx),
      }));
      setTableData(array);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const handleSearchListing = useCallback(debounce(handleYelpListing), [
    filterData,
    pageSize,
  ]);

  const handleSearch = (value) => {
    setSearchWord(value);
    handleSearchListing(value);
  };

  useEffect(() => {
    handleYelpListing();
  }, [page, pageSize, sortParam, filterData]);

  return (
    <TableWrapper>
      {filterModal && (
        <YelpFilterModal
          editDetails={filterData}
          handleClose={() => setFilterModal(false)}
          open={filterModal}
          setCurrentPage={setPage}
          setFilterData={setFilterData}
          setFilterPayload={setFilterPayload}
        />
      )}
      <div className="create-btn">
        <div>
          <form autoComplete="off" onSubmit={(e) => e?.preventDefault()}>
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => {
                e?.preventDefault();
                setPage(1);
                handleSearch(e?.target?.value);
              }}
              autoComplete="off"
            />
          </form>
        </div>
        {!userDetails?.isCompanyUser && (
          <div className="btn">
            <button
              style={{ width: "80px" }}
              onClick={() => setFilterModal(true)}
            >
              Filters
            </button>
          </div>
        )}
      </div>
      <div style={{ position: "relative" }}>
        <Table
          prefixCls="campaignTable"
          columns={columns}
          dataSource={tableData ?? []}
          pagination={false}
        />
        {loader && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#ffffff66",
            }}
          >
            <TableLoader data={tableData} />
          </div>
        )}
      </div>
      {!loader && (
        <div style={{ marginTop: "10px" }}>
          <Pagination
            prefixCls="interaction-pagination-night"
            current={page}
            page={page}
            pageSize={pageSize}
            onChange={onChangePagination}
            total={extraData}
            defaultPageSize={pageSize}
            showSizeChanger
          />
        </div>
      )}
    </TableWrapper>
  );
}

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 10px;
  img {
    cursor: pointer;
    height: 20px;
  }
`;

export const TableWrapper = styled.div`
  background: #f2f5fd;
  ${"" /* width: 100%; */}
  min-height: calc(100vh - 82px);
  height: calc(100% - 82px);
  height: 100%;
  padding: 30px;
  padding-top: 0px;

  .create-btn {
    display: flex;
    justify-content: end;
    padding: 20px 0px;
    ${"" /* margin-bottom: 10px; */}
    gap: 7px;

    input {
      height: 43px;
      width: 100%;
      background: #ffff;
      border-radius: 7px;
      border: 1px solid #cecece;
      padding: 0 10px;
      color: #000;
      font-family: "Poppins", Arial, sans-serif;

      &:focus {
        outline: none;
        border: 1px solid #000;
      }
    }

    .btn {
      button {
        background-color: #296eff;
        color: #fff;
        font-family: "Poppins", Arial, sans-serif;
        border-radius: 7px;
        font-weight: 500;
        font-size: 13px;
        border: none;
        padding: 0 10px;
        height: 43px;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }
`;
