import React from "react";
import { Modal, List } from "antd";
import PropTypes from "prop-types";

export const ArrivalWindowSettingData = ({
  isVisible,
  onClose,
  scheduleData,
}) => {
  const weekDaysOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const sortedTimeRanges = Object.keys(scheduleData)
    .sort((a, b) => {
      return weekDaysOrder.indexOf(a) - weekDaysOrder.indexOf(b);
    })
    .reduce((acc, key) => {
      acc[key] = scheduleData[key];
      return acc;
    }, {});

  const renderSchedule = () => {
    const days = Object.keys(sortedTimeRanges);
    return days.map((day, index) => (
      <List.Item key={index}>
        <span>
          {index + 1}. {day}: {sortedTimeRanges[day].join(", ")}
        </span>
      </List.Item>
    ));
  };

  return (
    <Modal
      title="Arrival Windows Settings"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      centered
    >
      <List>{renderSchedule()}</List>
    </Modal>
  );
};

ArrivalWindowSettingData.propTypes = {
  isVisible: PropTypes?.bool,
  onClose: PropTypes.func,
  scheduleData: PropTypes.object,
};
