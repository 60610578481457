/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { Pagination, Table, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteCompany,
  getCompanyListing,
  savePodiumToken,
  saveYelpToken,
} from "../../services/Collection";
import { error } from "../../utils/ApiErrorMessage";
import {
  CodeIcon,
  DeleteIcon,
  EditIcon,
  InfoSVGIcon,
  LinkSVGIcon,
} from "../../utils/SvgIcons";
import { DeleteModal } from "../../components/DeleteModal";
import { success } from "../../utils/ApiSuccessMessage";
import { AddCompanyModal } from "../../components/AddCompanyModal";
import SelectLocation from "../../components/SelectLocation";
import { APP_URL } from "../../utils/AppVariables";
import TableLoader from "../../components/TableLoader";
import { FilterModal } from "../../components/FilterModal";
import { debounce, srcSortImage } from "../../utils/CommonFunction";
import { ScriptModal } from "./ScriptModal";
import { YelpConnectModal } from "../../modals/YelpConnectModal";
import SelectYelpLocation from "../yelp/SelectYelpLocation";

export function DataTable() {
  const [pagesize, setPageSize] = useState(10);
  const [extraData, setExtraData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editDetails, setEditDetails] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [selectLocationModal, setSelectLocationModal] = useState(false);
  const [selectYelpLocationModal, setSelectYelpLocationModal] = useState(false);
  const location = useLocation();
  const [sortParam, setSortParam] = useState({
    sortType: "",
    sortBasis: "",
  });
  const [filterModal, setFilterModal] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [filterPayload, setFilterPayload] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  const navigate = useNavigate();
  const [openScriptModal, setScriptModal] = useState(false);
  const [yelpModal, setYelpModal] = useState(false);

  const getSortParam = (sortBasis) => {
    setCurrentPage(1);
    if (sortBasis === sortParam.sortBasis) {
      setSortParam({
        sortType: sortParam.sortType === "desc" ? "asc" : "desc",
        sortBasis,
      });
    } else {
      setSortParam({
        sortType: "desc",
        sortBasis,
      });
    }
  };

  const handleConnectCrm = (data) => {
    navigate(`/company/${data?.id}/crm`, { state: data });
  };

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "index",
      render: (val) => <p>{val}.</p>,
    },
    // {
    //   title: (
    //     <TitleWrapper>
    //       <span style={{ whiteSpace: "nowrap" }}>Company id</span>
    //       <img
    //         style={{ filter: sortParam?.sortBasis != "id" && "invert(1)" }}
    //         alt=""
    //         className="sortIcons"
    //         src={srcSortImage("id", sortParam)}
    //         onClick={() => getSortParam("id")}
    //       />
    //     </TitleWrapper>
    //   ),
    //   dataIndex: "id",
    //   render: (val) => <p>{val}</p>,
    // },
    {
      title: (
        <TitleWrapper>
          <span> Company name</span>
          <img
            // style={{ filter: sortParam?.sortBasis != "name" && "invert(1)" }}
            alt=""
            className="sortIcons"
            src={srcSortImage("name", sortParam)}
            onClick={() => getSortParam("name")}
          />
        </TitleWrapper>
      ),
      dataIndex: "name",
    },
    {
      title: (
        <TitleWrapper>
          <span> Source type</span>
          <img
            alt=""
            className="sortIcons"
            src={srcSortImage("type", sortParam)}
            onClick={() => getSortParam("type")}
          />
        </TitleWrapper>
      ),
      dataIndex: "type",
    },
    {
      title: (
        <TitleWrapper>
          <span>Active</span>
          <img
            alt=""
            className="sortIcons"
            src={srcSortImage("active", sortParam)}
            onClick={() => getSortParam("active")}
          />
        </TitleWrapper>
      ),
      dataIndex: "active",
      render: (val) => <p>{val ? "True" : "False"}</p>,
    },
    {
      title: "Embedded code",
      render: (_, data) => (
        <PodiumStatusWrapper>
          <button
            style={{
              display: "flex",
              gap: "5px",
              minWidth: "135px",
              whiteSpace: "nowrap",
            }}
            className="btn-connect"
            onClick={() => {
              setEditDetails(data);
              setScriptModal(true);
            }}
            // onClick={() => openChatInNewTab(data)}
          >
            <CodeIcon />
            Embedded code
          </button>
        </PodiumStatusWrapper>
      ),
    },
    {
      title: "Booking Info",
      render: (_, data) => (
        <PodiumStatusWrapper>
          <button
            style={{
              display: "flex",
              gap: "5px",
            }}
            className="btn-connect"
            onClick={() =>
              navigate(`/company/${data?.id}/bookings`, { state: data })
            }
          >
            <InfoSVGIcon />
            Bookings
          </button>
        </PodiumStatusWrapper>
      ),
    },
    {
      title: "CRM Status",
      dataIndex: "podium_id",
      render: (val, data) => (
        <PodiumStatusWrapper>
          <button
            style={{
              display: "flex",
              gap: "5px",
            }}
            className="btn-connect"
            onClick={() => handleConnectCrm(data)}
          >
            <LinkSVGIcon />
            Link
          </button>
        </PodiumStatusWrapper>
      ),
    },
    // {
    //   title: "Podium Status",
    //   dataIndex: "podium_id",
    //   render: (val, data) => (
    //     <PodiumStatusWrapper>
    //       {val ? (
    //         <button className="btn-connected">Connected</button>
    //       ) : data?.id == editDetails?.id && actionLoading ? (
    //         <button className="btn-connect">Loading...</button>
    //       ) : (
    //         <button
    //           className="btn-connect"
    //           onClick={() => {
    //             handleConnectPodium(data?.id);
    //             setEditDetails(data);
    //           }}
    //         >
    //           Connect
    //         </button>
    //       )}
    //     </PodiumStatusWrapper>
    //   ),
    // },
    {
      title: "Yelp Status",
      dataIndex: "yelp",
      render: (val, data) => (
        <PodiumStatusWrapper>
          {data?.type == "yelp" ? (
            <>
              {val ? (
                <button
                  onClick={() => {
                    setYelpModal(true);
                    setEditDetails(data);
                  }}
                  style={{ whiteSpace: "nowrap" }}
                  className="btn-connected"
                >
                  Connected ({val})
                </button>
              ) : (
                <button
                  className="btn-connect"
                  onClick={() => {
                    // handleConnectPodium(data?.id);
                    setYelpModal(true);
                    setEditDetails(data);
                  }}
                >
                  Connect
                </button>
              )}
            </>
          ) : (
            <p />
          )}
        </PodiumStatusWrapper>
      ),
    },
    {
      title: "Actions",
      render: (_, data) => (
        <ActionButtons>
          <div
            className="edit-icon"
            onClick={() => {
              setEditModal(true);
              setEditDetails(data);
            }}
          >
            <EditIcon />
          </div>
          <div
            className="delete-icon"
            onClick={() => {
              setDeleteModal(true);
              setEditDetails(data);
            }}
          >
            <DeleteIcon />
          </div>
        </ActionButtons>
      ),
    },
  ];

  // const handleConnectPodium = async (id) => {
  //   setActionLoading(true);
  //   const res = await redirectPodium(id);
  //   if (res?.url) {
  //     setActionLoading(false);
  //     window.location.href = res?.url;
  //   } else {
  //     setActionLoading(false);
  //   }
  // };

  const setInitialValues = () => {
    setFilterPayload([]);
    setFilterData(null);
    setSortParam({
      sortType: "",
      sortBasis: "",
    });
    setSearchWord("");
    setCurrentPage(1);
  };

  const handleSavePodiumToken = async () => {
    const companyId = new URLSearchParams(location.search).get("state");
    const token = new URLSearchParams(location.search).get("code");
    const requestPayload = {
      company_id: Number(companyId),
      code: token,
    };
    const res = await savePodiumToken(requestPayload);
    if (res?.data) {
      if (res?.data?.length > 0) {
        setSelectLocationModal(true);
        setLocationData(res?.data);
      } else {
        window.location.href = APP_URL;
      }
    } else {
      // error(messageApi, message);
    }
  };

  const handleSaveYelpToken = async () => {
    const yelp_id = new URLSearchParams(location?.search).get("state");
    const token = new URLSearchParams(location?.search).get("code");
    const requestPayload = {
      yelp_id,
      code: token,
    };

    const res = await saveYelpToken(requestPayload);
    if (res?.status === 200) {
      window.location.href = APP_URL;
    } else {
      // error(messageApi, "Errorr");
    }
  };

  const handleCompaniesListing = async (search) => {
    setLoading(true);
    const param = new URLSearchParams();
    sortParam?.sortBasis && param.append("sort_by", sortParam?.sortBasis);
    sortParam?.sortType && param.append("order_by", sortParam?.sortType);
    pagesize && param.append("page_size", Number(pagesize));
    currentPage && param.append("page", Number(currentPage));
    if (search !== null || search !== "") {
      if (search === undefined) {
        if (searchWord) param.append("search", searchWord);
      } else {
        search && param.append("search", search);
      }
    }

    const res = await getCompanyListing(
      param?.toString(),
      filterPayload?.length > 0 && filterPayload
    );
    if (res?.data?.length > 0) {
      const filterArray = [];

      res?.data?.length > 0 &&
        res?.data?.map((el, index) => {
          const obj = {
            ...el,
            operationHours: `${el?.operation_start} - ${el?.operation_end}`,
            index: pagesize * (currentPage - 1) + (1 + index),
          };
          filterArray?.push(obj);
        });
      setExtraData(res?.total_count);
      setTableData(filterArray);
      setLoading(false);
    } else {
      setExtraData(0);
      setLoading(false);
      setTableData([]);
    }
  };
  const handleSearchListing = useCallback(debounce(handleCompaniesListing), [
    filterPayload,
  ]);

  const handleSearch = (value) => {
    setSearchWord(value);
    handleSearchListing(value);
  };

  const handleDeleteCompany = async () => {
    setDeleteLoading(true);
    const res = await deleteCompany(editDetails?.id);
    if (res?.message == "Company deleted successfully") {
      success(messageApi, "Company Deleted Successfully");
      setDeleteLoading(false);
      setDeleteModal(false);
      if (currentPage == 1) {
        handleCompaniesListing();
      } else {
        setCurrentPage(1);
      }
    } else {
      const message =
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong";
      error(messageApi, message);
      setDeleteLoading(false);
    }
  };

  const handleIsYelpSaveToken = (isYelp) => {
    if (isYelp) {
      if (
        window?.location?.search?.includes("code") &&
        window?.location?.pathname?.includes("yelp")
      ) {
        return true;
      }
      return false;
    }
    if (
      window?.location?.search?.includes("code") &&
      !window?.location?.pathname?.includes("yelp")
    ) {
      return true;
    }
    return false;
  };

  const onChangePagination = (e, limit) => {
    setCurrentPage(e);
    setPageSize(limit);
  };

  useEffect(() => {
    handleCompaniesListing();
  }, [sortParam, pagesize, currentPage, filterData]);

  useEffect(() => {
    if (handleIsYelpSaveToken(false)) {
      handleSavePodiumToken();
    } else if (handleIsYelpSaveToken(true)) {
      handleSaveYelpToken();
    }
  }, [window.location.search]);

  return (
    <TableWrapper>
      {contextHolder}

      {openScriptModal && (
        <ScriptModal
          open={openScriptModal}
          handleClose={() => setScriptModal(false)}
          editDetails={editDetails}
        />
      )}

      {filterModal && (
        <FilterModal
          open={filterModal}
          handleClose={() => setFilterModal(false)}
          editDetails={filterData}
          setFilterData={setFilterData}
          setCurrentPage={setCurrentPage}
          setFilterPayload={setFilterPayload}
          setSearchWord={setSearchWord}
        />
      )}

      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          handleClose={() => setDeleteModal(false)}
          title="Are you sure you want to Delete this company ?"
          description="All your process and data will be saved."
          isLoading={deleteLoading}
          handleSubmit={handleDeleteCompany}
        />
      )}
      {addModal && (
        <AddCompanyModal
          open={addModal}
          handleClose={() => setAddModal(false)}
          messageApi={messageApi}
          listingApi={handleCompaniesListing}
          setFilterData={setFilterData}
          setInitialValues={setInitialValues}
        />
      )}
      {editModal && (
        <AddCompanyModal
          open={editModal}
          handleClose={() => setEditModal(false)}
          editDetails={editDetails}
          modalType="edit"
          messageApi={messageApi}
          listingApi={handleCompaniesListing}
          setFilterData={setFilterData}
          setInitialValues={setInitialValues}
        />
      )}

      {yelpModal && (
        <YelpConnectModal
          open={yelpModal}
          handleClose={() => setYelpModal(false)}
          editDetails={editDetails}
        />
      )}

      {selectLocationModal && (
        <SelectLocation
          open={selectLocationModal}
          onClose={setSelectLocationModal}
          locationData={locationData}
          id={new URLSearchParams(location?.search).get("state")}
          messageApi={messageApi}
        />
      )}

      {selectYelpLocationModal && (
        <SelectYelpLocation
          open={selectYelpLocationModal}
          onClose={setSelectYelpLocationModal}
          locationData={locationData}
          id={new URLSearchParams(location?.search).get("state")}
          messageApi={messageApi}
        />
      )}

      <div className="create-btn">
        <div>
          <form autoComplete="off" onSubmit={(e) => e?.preventDefault()}>
            <input
              type="text"
              name="search"
              value={searchWord}
              placeholder="Search"
              onChange={(e) => {
                // e?.preventDefault();
                setCurrentPage(1);
                handleSearch(e?.target?.value);
              }}
            />
          </form>
        </div>
        <div className="btn-container">
          <div className="btn">
            <button className="filterBtn" onClick={() => setFilterModal(true)}>
              Filters
            </button>
          </div>
          <div className="btn">
            <button onClick={() => setAddModal(true)}>Add a new company</button>
          </div>
        </div>
      </div>

      <div style={{ position: "relative" }}>
        <Table
          prefixCls="campaignTable"
          columns={columns}
          dataSource={tableData}
          pagination={false}
        />

        {loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#ffffff66",
            }}
          >
            <TableLoader data={tableData} />
          </div>
        )}
      </div>

      <div style={{ marginTop: "10px" }}>
        {!loading && (
          <Pagination
            prefixCls="interaction-pagination-night"
            current={currentPage}
            onChange={onChangePagination}
            total={extraData}
            defaultPageSize={pagesize}
            showSizeChanger
          />
        )}
      </div>
    </TableWrapper>
  );
}

const TableWrapper = styled.div`
  background: #f2f5fd;
  ${"" /* background: #00050b; */}
  min-height: calc(100vh - 82px);
  height: calc(100% - 82px);
  height: 100%;
  padding: 30px;
  padding-top: 0px;

  .create-btn {
    display: flex;
    justify-content: end;
    padding: 20px 0px;
    ${"" /* margin-bottom: 10px; */}
    gap: 10px;
    @media (max-width: 560px) {
      flex-direction: column;
    }

    .btn-container {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    input {
      height: 43px;
      width: 100%;
      background: #ffff;
      border-radius: 7px;
      border: 1px solid #cecece;
      padding: 0 10px;
      color: #000;
      font-family: "Poppins", Arial, sans-serif;

      &:focus {
        outline: none;
        border: 1px solid #000;
      }
    }

    .btn {
      width: 100%;
      button {
        background-color: #296eff;
        color: #fff;
        font-family: "Poppins", Arial, sans-serif;
        border-radius: 7px;
        font-weight: 500;
        font-size: 13px;
        border: none;
        padding: 0 10px;
        height: 43px;
        cursor: pointer;
        white-space: nowrap;

        @media (max-width: 560px) {
          width: 100%;
        }
      }
      .filterBtn {
        width: 80px;
        @media (max-width: 560px) {
          width: 100%;
        }
      }
    }
  }
`;

const ActionButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 6px;

  .edit-icon {
    background: #ff9b29;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    display: grid;
    cursor: pointer;
    place-items: center;
  }
  .delete-icon {
    background: red;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    display: grid;
    cursor: pointer;
    place-items: center;
  }
`;

const PodiumStatusWrapper = styled.div`
  width: 100%;

  .btn-connect {
    border-radius: 12px;
    padding: 3px 8px;
    border-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #cdcdcd;
    color: #296eff;
    font-family: "Poppins", Arial, sans-serif;
    cursor: pointer;

    &:hover {
      background: #296eff24;
    }
  }
  .btn-connected {
    border-radius: 12px;
    padding: 3px 8px;
    border-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #71b497;
    color: #71b497;
    font-family: "Poppins", Arial, sans-serif;
    cursor: pointer;
  }
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  img {
    cursor: pointer;
    height: 20px;
  }
`;
