/* eslint-disable react/jsx-key */
import React from "react";
import { Outlet, useParams } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import ClickAwayListener from "react-click-away-listener";
import Header from "../components/Header";
import { OpenSideNav } from "../auth/LoginSlice";
import Sidenav from "../components/Sidenav";
import { DummyComponent } from "../components/DummyComponent";

export function PrivateLayout() {
  const sidebar = useSelector((state) => state?.LoginSlice?.sideNav);
  const dispatch = useDispatch();
  const { company_id } = useParams();
  const handleClickAway = () => {
    dispatch(OpenSideNav(false));
  };

  return (
    <PrivateLayoutWrapper sidebar={sidebar}>
      <div style={{ height: "100%" }}>
        <div className="header">
          <Header />
        </div>
        <div style={{ height: "100%" }}>
          {/* {click ? ( */}
          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              <div
                style={{ left: !sidebar ? -315 : 0 }}
                className="sidebar-section"
              >
                <Sidenav />
              </div>
              {!company_id && <DummyComponent />}
            </div>
          </ClickAwayListener>

          <section className="outlet-section">
            <div className="outlet-section-inner">
              <Outlet />
            </div>
          </section>
        </div>
      </div>
    </PrivateLayoutWrapper>
  );
}

const PrivateLayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #f2f5fd;
  transition: all 0.3s;

  .header {
    width: 100%;
    height: 62px;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .sidebar-section {
    width: 315px;
    height: 100vh;
    background: #f2f5fd;
    position: fixed;
    z-index: 99999999;
    transition: all 0.5s;
    @media (max-height: 600px) {
      overflow-y: scroll;
    }

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: #f2f5fd;
      border-radius: 10px;
    }

    @media (max-width: 992px) {
      display: ${({ sidebar }) => !sidebar && "none"};
    }
  }

  .outlet-section {
    width: calc(100% - 0px);
    background: #f2f5fd;
    height: 100%;

    .outlet-section-inner {
      position: relative;
      width: 100%;
      height: 100%;
      background: #f2f5fd;
    }

    @media (max-width: 992px) {
      width: 100%;
      margin: 0;
    }
  }
`;
