import { Modal } from "antd";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

export function DeleteModal({
  open,
  handleClose,
  isLoading,
  title,
  description,
  handleSubmit,
}) {
  return (
    <Modal
      open={open}
      centered
      width={500}
      footer={false}
      onCancel={handleClose}
      closeIcon={<span />}
      prefixCls="delete-modal"
    >
      <DeleteModalWrapper>
        <div className="title">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <div className="btns">
          <button onClick={handleClose} className="cancel">
            Cancel
          </button>

          {isLoading ? (
            <span className="delete">Loading...</span>
          ) : (
            <button onClick={handleSubmit} className="delete">
              Delete
            </button>
          )}
        </div>
      </DeleteModalWrapper>
    </Modal>
  );
}

DeleteModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  handleSubmit: PropTypes.func,
};

const DeleteModalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  gap: 32px;
  padding: 20px;
  border: 1px solid #fff;
  border-radius: 10px;

  .title {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;

    h2 {
      color: #2c2c2c;
      font-family: "Poppins";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
    }

    p {
      color: #2c2c2c;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      span {
        color: red;
      }
    }
  }

  .btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    span {
      height: 44px;
      padding: 0 24px;
      border-radius: 100px;
      border-style: none;
      font-family: Manrope;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      color: #ffffff;
      background: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cancel {
      width: 100%;
      height: 48px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px solid #000;
      color: #000;
      background: transparent;
      text-align: center;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      cursor: pointer;
      letter-spacing: 0.2px;
    }
    .delete {
      width: 100%;
      height: 48px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: none;
      color: #fff;
      background-color: red;
      text-align: center;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      cursor: pointer;
      letter-spacing: 0.2px;
    }
  }
`;
