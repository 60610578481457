import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import LoginSlice from "../auth/LoginSlice";
import MessageSlice from "./MessageSlice";

const reducers = combineReducers({
  LoginSlice,
  MessageSlice,
});

const persistConfig = {
  key: "2.9",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
