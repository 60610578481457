import { Sort, SortAsc, SortDesc } from "./Images";

export const debounce = (func, timeOut = 400) => {
  let timer;
  return (...args) => {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, timeOut);
  };
};

export const srcSortImage = (Basis, sortParam) => {
  if (Basis === sortParam.sortBasis) {
    if (sortParam.sortType === "asc") {
      return SortAsc;
    }
    return SortDesc;
  }
  return Sort;
};

export const smsFeaturesAccess = [38];

export const valueFormatter = (num, digits) => {
  if (num > 999) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        console.log(item, "valueFormatter if 1");
        return item ? num >= item.value : "0";
      });
    console.log(item, "valueFormatter if 2");
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  } else {
    console.log(num, "valueFormatter else");
    return num ? Math.floor(num) : "0";
  }
};
