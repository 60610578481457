import { ConfigProvider, Switch } from "antd";
import React from "react";
import PropTypes from "prop-types";

export function SwitchField({
  value,
  handleChange,
  name,
  data,
  switchLoading,
}) {
  return (
    <div style={{ width: "100%" }} className="fields">
      {/* <label>After Hours </label> */}
      <ConfigProvider
        theme={{
          components: {
            Switch: {
              colorPrimary: "#296eff",
              handleBg: "#fff",
              colorPrimaryHover: "#296eff",
              colorTextQuaternary: "#2c2c2c",
              colorTextTertiary: "#2c2c2c",
            },
          },
        }}
      >
        <Switch
          prefixCls="custom-switch"
          value={value}
          loading={
            switchLoading?.id === data?.id && switchLoading?.name === name
          }
          onChange={(e) => handleChange(e, name, data)}
        />
      </ConfigProvider>
    </div>
  );
}

SwitchField.propTypes = {
  value: PropTypes.bool,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  data: PropTypes.any,
  switchLoading: PropTypes.bool,
};
