import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { OpenSideNav } from "../auth/LoginSlice";

export function DummyComponent() {
  const dispatch = useDispatch();
  const isSideBarOpen = useSelector((state) => state?.LoginSlice?.sideNav);
  return (
    <ResponsiveHeaderButton
      onClick={() => dispatch(OpenSideNav(!isSideBarOpen))}
    />
  );
}

export const ResponsiveHeaderButton = styled.div`
  width: 40px;
  height: 35px;
  position: fixed;
  top: 23px;
  left: 24px;
  cursor: pointer;
  z-index: 999;
`;
