import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import PageNotFoundAnimation from "../lotties/PageNotFoundAnimation.json";
import PageNotFoundText from "../lotties/PageNotFoundText.json";

function PageNotFound() {
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: PageNotFoundAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const textOption = {
    loop: true,
    autoplay: true,
    animationData: PageNotFoundText,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <PageNotFoundBox>
      <div>
        <Lottie options={defaultOptions} height={340} width={400} />
        <Lottie options={textOption} width={220} />
        <div className="btn-wrapper">
          <button className="button" onClick={() => navigate(-1)}>
            BACK
          </button>
        </div>
      </div>
    </PageNotFoundBox>
  );
}

export default PageNotFound;

const PageNotFoundBox = styled.div`
  width: 100%;
  height: 100vh;
  background: #f2f5fd;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-wrapper {
    display: flex;
    justify-content: center;

    .button {
      width: 150px;
      display: flex;
      height: 48px;
      padding: 21px 24px;
      justify-content: center;
      align-items: center;
      background: #296eff;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid #296eff;
      color: #fff;
      text-align: center;
      cursor: pointer;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: 0.2px;
    }
  }
`;
