import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import { DatePicker, Spin, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  AvatarBoy,
  AvatarGamer,
  AvatarMan,
  AvatarMan2,
  AvatarUser,
  AvatarWoman,
  chat,
  people,
} from "../../utils/Images";
import { getMessages } from "../../services/Collection";
import {
  updateMessageType,
  updateSelectedChat,
} from "../../store/MessageSlice";
import { BackSVGIcon } from "../../utils/SvgIcons";
import { debounce, smsFeaturesAccess } from "../../utils/CommonFunction";
import { useInfiniteScroll } from "../../customHook/InfiniteScroll";
import useWindowWidth from "../../customHook/GetWindowWidth";
import YelpFilterModal from "../../modals/YelpFilterModal";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const { RangePicker } = DatePicker;

const avatarArray = [
  AvatarBoy,
  AvatarGamer,
  AvatarWoman,
  AvatarMan,
  AvatarMan2,
  AvatarUser,
];

function Conversation() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [messagesList, setMessagesList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchWord, setSearchWord] = useState("");
  const messageType = useSelector((state) => state?.MessageSlice?.messageType);
  const [responsiveChat, setResponsiveChat] = useState("user");
  const selectedChat = useSelector(
    (state) => state?.MessageSlice?.selectedChat
  );
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const [paginationLoader, setPaginationLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [loadMore] = useInfiniteScroll({
    elementId: "scrollable-container",
    height: 100,
  });
  const [width] = useWindowWidth();
  const searchRef = useRef("");
  const bottomRef = useRef(null);
  const [filterModal, setFilterModal] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [filterPayload, setFilterPayload] = useState([]);
  const navigate = useNavigate();
  const AiDate = useSelector((state) => state?.LoginSlice);
  var enddate = new Date();
  var startdate = new Date(new Date().setDate(enddate.getDate() - 30));
  const initialDate = {
    startDate: moment(AiDate?.startDate ?? startdate).format("YYYY-MM-DD"),
    endDate: moment(AiDate?.endDate ?? enddate).format("YYYY-MM-DD"),
  };

  const [dateFilter, setDateFilter] = useState([]);
  let { company_id } = useParams();

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleMessageType = (el) => {
    if (!loading) {
      dispatch(updateMessageType(el));
      setPage(1);
      searchRef.current.value = "";
      setSearchWord("");

      if (messageType !== el) {
        setResponsiveChat("user");
        dispatch(updateSelectedChat(null));
        setMessagesList([]);
      }
    }
  };

  const handleList = async (search, pageNumber) => {
    if (page === 1 || pageNumber === 1) {
      setLoading(true);
    } else {
      setPaginationLoader(true);
    }
    const params = new URLSearchParams();
    params.append("page", page);
    params.append("page_size", 20);
    params.append("sort_by", "modified_at");
    params.append("order_by", "desc");
    if (company_id) {
      initialDate?.startDate &&
        params.append("start_time", initialDate?.startDate);
      initialDate?.endDate && params.append("end_time", initialDate?.endDate);
    } else {
      dateFilter?.[0] && params.append("start_time", dateFilter?.[0]);
      dateFilter?.[1] && params.append("end_time", dateFilter?.[1]);
    }
    if (search !== null || search !== "") {
      if (search === undefined) {
        if (searchWord) params.append("search", searchWord);
      } else {
        search && params.append("search", search);
      }
    }

    let filters;
    if (company_id) {
      filters = [
        {
          field: "company_id",
          value: company_id,
        },
      ];
    }
    const res = await getMessages(
      messageType,
      params,
      filterPayload?.length > 0 ? filterPayload : company_id ? filters : null
    );
    if (res?.status === 200) {
      if (page === 1 || pageNumber === 1) {
        setLoading(false);
      } else {
        setPaginationLoader(false);
      }
      setTotalCount(res?.total_count);
      if (page === 1) {
        setMessagesList(res?.data);
      } else {
        setMessagesList((prevMessages) => [
          ...prevMessages,
          ...(res?.data ?? []),
        ]);
      }
    } else if (page === 1 || pageNumber === 1) {
      setLoading(false);
    } else {
      setPaginationLoader(false);
    }
  };

  const handleSearchListing = useCallback(debounce(handleList), [
    messageType,
    filterPayload,
  ]);

  const handleSearch = (value) => {
    setSearchWord(value);
    setResponsiveChat("user");
    handleSearchListing(value, 1);
  };

  const getByTitle = (el, smsType) => {
    if (messageType === "chatwidget") {
      if (smsType) {
        return "Customer";
      }
      return el?.title;
    }
    if (messageType === "sms") {
      const title = `${el?.sms_user?.lead_phone}(${el?.sms_user?.lead_first_name} ${el?.sms_user?.lead_last_name})`;
      if (smsType) {
        return !el?.sms_user?.lead_first_name && !el?.sms_user?.lead_last_name
          ? el?.sms_user?.lead_phone
          : `${el?.sms_user?.lead_first_name}  ${el?.sms_user?.lead_last_name}`;
      }
      return title;
    }
    if (messageType === "yelp") {
      return el?.yelp_user?.name;
    }
    if (messageType === "lsa") {
      return el?.lsa_user?.lead_phone;
    }
  };

  const getMessageContent = (d) => {
    const f = [...d].reverse();

    return f;
  };

  useEffect(() => {
    handleList();
  }, [messageType, page, filterPayload, dateFilter]);

  useEffect(() => {
    if (loadMore && messagesList?.length < totalCount) {
      setPage(page + 1);
    }
  }, [loadMore]);

  useEffect(() => {
    if (width <= 650) {
      dispatch(updateSelectedChat(null));
    }
  }, []);

  return (
    <ConversationBox>
      {filterModal && (
        <YelpFilterModal
          editDetails={filterData}
          handleClose={() => setFilterModal(false)}
          open={filterModal}
          setCurrentPage={setPage}
          setFilterData={setFilterData}
          setFilterPayload={setFilterPayload}
        />
      )}
      <div className="chat-header">
        <div className="tabs">
          <button
            className={messageType === "chatwidget" && "active"}
            onClick={() => handleMessageType("chatwidget")}
          >
            Chat Widget
          </button>
          {!smsFeaturesAccess?.includes(userDetails?.id) && (
            <button
              className={messageType === "sms" && "active"}
              onClick={() => handleMessageType("sms")}
            >
              SMS Message
            </button>
          )}
          <button
            className={messageType === "lsa" && "active"}
            onClick={() => handleMessageType("lsa")}
          >
            LSA Message
          </button>
          <button
            className={messageType === "yelp" && "active"}
            onClick={() => handleMessageType("yelp")}
          >
            Yelp
          </button>
          {userDetails?.isCompanyUser && (
            <button
              className={messageType === "booking" && "active"}
              onClick={() => navigate("/booking")}
            >
              Bookings
            </button>
          )}
        </div>
        <div className="search-box">
          {!company_id && (
            <div className="rangePicker">
              <RangePicker
                prefixCls="range-picker"
                onChange={(_, e) => {
                  setDateFilter(e);
                  setResponsiveChat("user");
                  dispatch(updateSelectedChat(null));
                  setMessagesList([]);
                }}
              />
            </div>
          )}
          <form autoComplete="off" onSubmit={(e) => e?.preventDefault()}>
            <input
              type="text"
              ref={searchRef}
              placeholder="Search"
              onChange={(e) => {
                e?.preventDefault();
                handleSearch(e?.target?.value);
              }}
              autoComplete="off"
            />
          </form>

          {!userDetails?.isCompanyUser && !company_id && (
            <div className="tabs">
              <button
                className="filterBtn"
                onClick={() => setFilterModal(true)}
              >
                Filters
              </button>
            </div>
          )}
        </div>
      </div>

      <ChatSection id="scrollable-container" responsiveChat={responsiveChat}>
        <div className="sidenav">
          {loading ? (
            <LoadingBox>
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 26,
                      color: "#296eff",
                    }}
                    spin
                  />
                }
              />
            </LoadingBox>
          ) : (
            <>
              {messagesList?.length > 0 ? (
                messagesList?.map((el, idx) => (
                  <div
                    key={idx}
                    className={`chat-users ${
                      el?.id === selectedChat?.id && "active"
                    } `}
                    onClick={() => {
                      dispatch(updateSelectedChat(el));
                      setResponsiveChat("playground");
                      scrollToBottom();
                    }}
                  >
                    <img src={avatarArray[idx % 6]} alt="" />
                    {messageType === "sms" ? (
                      <div className="smsUserList">
                        <Tooltip
                          prefixCls="tooltip-box"
                          title={
                            el?.sms_user?.lead_first_name +
                            " " +
                            el?.sms_user?.lead_last_name
                          }
                        >
                          <h4
                            style={{
                              maxWidth: "calc(100% - 20px)",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            {el?.sms_user?.lead_first_name}{" "}
                            {el?.sms_user?.lead_last_name}
                          </h4>
                        </Tooltip>
                        <Tooltip
                          prefixCls="tooltip-box"
                          title={el?.sms_user?.lead_phone}
                        >
                          <p
                            style={{
                              maxWidth: "calc(100% - 20px)",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            {el?.sms_user?.lead_phone}
                          </p>
                        </Tooltip>
                      </div>
                    ) : (
                      <Tooltip prefixCls="tooltip-box" title={getByTitle(el)}>
                        <p
                          style={{
                            maxWidth: "calc(100% - 20px)",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {getByTitle(el)}
                        </p>
                      </Tooltip>
                    )}
                    {el?.booked && <div className="booked">B</div>}
                  </div>
                ))
              ) : (
                <div className="no-chats">
                  <img src={people} alt="" />
                  <h2>No Users</h2>
                </div>
              )}
            </>
          )}
          {paginationLoader && (
            <div className="pagination-loader">
              <LoadingBox>
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                        color: "#296eff",
                      }}
                      spin
                    />
                  }
                />
              </LoadingBox>
            </div>
          )}
        </div>
        <div className="chat-playground" id="bottom" ref={bottomRef}>
          {selectedChat && (
            <div className="header">
              <div className="header-content">
                <div>
                  <span
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      gap: "5px",
                      fontFamily: "Poppins",
                      color: "white",
                    }}
                    onClick={() => {
                      setResponsiveChat("user");
                      dispatch(updateSelectedChat(null));
                    }}
                  >
                    <BackSVGIcon />
                  </span>
                </div>
                <div style={{ height: "40px" }} className="chat-users">
                  <img src={avatarArray[1]} alt="" />
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#2c2c2c",
                      fontWeight: 500,
                    }}
                  >
                    {messageType === "sms"
                      ? getByTitle(selectedChat, true)
                      : getByTitle(selectedChat, false)}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="inner-inbox">
            {selectedChat == null ? (
              <div className="no-chats">
                <img src={chat} alt="" />
                <h2>No Chat Selected</h2>
              </div>
            ) : (
              <>
                {selectedChat?.content?.length > 0 ? (
                  getMessageContent(selectedChat?.content)?.map((el, idx) => (
                    <section
                      style={{
                        flexDirection:
                          el?.role === "user" ? "row" : "row-reverse",
                      }}
                      className="messages-users"
                      key={idx}
                    >
                      <div>
                        <img
                          src={
                            el?.role === "user"
                              ? avatarArray[1]
                              : avatarArray[3]
                          }
                          alt=""
                        />
                      </div>

                      <div
                        style={{
                          alignItems: el?.role === "user" ? "start" : "end",
                        }}
                        className="user-title"
                      >
                        {el?.role === "user" ? (
                          <div>
                            <p>
                              {messageType === "sms" ||
                              messageType === "chatwidget"
                                ? getByTitle(selectedChat, true)
                                : getByTitle(selectedChat, false)}
                            </p>
                          </div>
                        ) : (
                          <div className="right-avatar">
                            <p>Assistant</p>
                          </div>
                        )}

                        <div
                          key={idx}
                          className={`message ${
                            el?.role === "user" ? "left" : "right"
                          } `}
                          style={{
                            alignItems: el?.role === "user" && "start",
                            background: el?.role !== "user" && "#296eff",
                            color: el?.role !== "user" && "#fff",
                          }}
                        >
                          <div className="content">{el?.content}</div>

                          <div
                            className="time"
                            style={{
                              textAlign: "end",
                              float: "right",
                              fontSize: 12,
                              color: el?.role !== "user" ? "#fff" : "#2c2c2c",
                              marginTop: "5px",
                            }}
                          >
                            {el?.time}
                          </div>
                        </div>
                      </div>
                    </section>
                  ))
                ) : (
                  <div className="no-chats">
                    <img src={chat} alt="" />
                    <h2>No Chats</h2>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </ChatSection>
    </ConversationBox>
  );
}

export default Conversation;

const ConversationBox = styled.div`
  background: #f2f5fd;
  width: 100%;
  height: calc(100% - 82px);
  min-height: calc(100vh - 82px);
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 10px;

  .rangePicker {
    input {
      height: 34px !important;
      width: 90px !important;
      background: unset !important;
      border-radius: unset !important;
      border: unset !important;
      padding: unset !important;
      color: unset !important;
      font-family: "Poppins", Arial, sans-serif;

      &:focus {
        ${"" /* outline: none; */}
        border: unset !important;
      }
    }
  }

  .search-box {
    display: flex;
    gap: 7px;

    button {
      padding: 8px 20px;
    }
  }

  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 650px) {
      flex-direction: column-reverse;
      align-items: start;
      gap: 10px;
    }

    form {
      @media (max-width: 445px) {
        width: 100%;
      }
    }

    input {
      height: 43px;
      width: 100%;
      background: #ffff;
      border-radius: 7px;
      border: 1px solid #cecece;
      padding: 0 10px;
      color: #000;
      font-family: "Poppins", Arial, sans-serif;

      &:focus {
        outline: none;
        border: 1px solid #000;
      }

      @media (max-width: 445px) {
        width: 100%;
      }
    }
  }

  .tabs {
    display: flex;
    gap: 10px;
    align-items: center;

    @media (max-width: 445px) {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  button {
    background-color: #fff;
    color: #2c2c2c;
    font-family: "Poppins", Arial, sans-serif;
    border-radius: 7px;
    font-weight: 500;
    font-size: 13px;
    border: none;
    padding: 0 10px;
    height: 43px;
    cursor: pointer;
    white-space: nowrap;
    box-shadow: 0px 2px 8px rgba(61, 107, 192, 0.28);
  }
  .filterBtn {
    background-color: #296eff;
    color: #fff;
    font-family: "Poppins", Arial, sans-serif;
    border-radius: 7px;
    font-weight: 500;
    font-size: 13px;
    border: none;
    padding: 0 10px;
    height: 43px;
    cursor: pointer;
    white-space: nowrap;
  }
  .active {
    background-color: #296eff;
    color: #fff;
  }
`;

const ChatSection = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  gap: 10px;
  position: relative;

  .messages-users {
    display: flex;
    gap: 5px;
  }

  .user-title {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;

    p {
      font-size: 14px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      color: #2c2c2c;
    }
  }

  .smsUserList {
    width: 75%;
    overflow: hidden;

    h4 {
      font-size: 16px;
      font-family: "Poppins" sans-serif;
      font-weight: 500;
    }
    p {
      font-size: 12px;
      font-family: "Poppins" sans-serif;
      font-weight: 500;
    }
  }

  .pagination-loader {
    width: 100%;
    height: 70px;
  }

  .sidenav {
    width: 35%;
    height: calc(100vh - 175px);
    overflow-y: scroll;
    border: 1px solid #cecece;
    border-radius: 7px;
    background: #fff;

    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #c4bdbd;
      border-radius: 10px;
    }

    @media (max-width: 650px) {
      display: ${({ responsiveChat }) =>
        responsiveChat === "playground" && "none"};
      width: ${({ responsiveChat }) => responsiveChat === "user" && "100%"};
      height: calc(100vh - 275px);
    }

    .chat-users {
      color: #2c2c2c;
      font-family: "Poppins", Arial, sans-serif;
      font-size: 16px;
      border: none;
      font-weight: 600;
      height: 63px;
      cursor: pointer;
      display: flex;
      gap: 10px;
      align-items: center;
      padding-left: 20px;
      border-bottom: 1px solid #cecece;

      &:hover {
        background-color: #f2f5fd;
      }
    }

    .booked {
      color: white;
      background: #446fff;
      width: 25px;
      height: 25px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .no-chats {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h2 {
        margin-top: 5px;
        color: #2c2c2c99;
        font-family: "Poppins", Arial, sans-serif;
      }
    }
    .active {
      background: #296eff;
      color: #fff;

      &:hover {
        background: #296eff;
      }
      .smsUserList {
        width: 100%;

        h4 {
          color: #fff;
        }
        p {
          color: #fff;
        }
      }
    }
  }

  .chat-playground {
    width: calc(100% - 240px);
    height: calc(100vh - 175px);
    border: 1px solid #cecece;
    border-radius: 7px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    background: #fff;

    @media (max-width: 650px) {
      display: ${({ responsiveChat }) => responsiveChat === "user" && "none"};
      width: ${({ responsiveChat }) =>
        responsiveChat === "playground" && "100%"};
      height: calc(100vh - 275px);
    }

    .header {
      position: sticky;
      top: 0;
      height: 40px !important;
      ${"" /* display: none; */}

      .header-content {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .chat-users {
        color: #fff;
        font-family: "Poppins", Arial, sans-serif;
        font-size: 16px;
        border: none;
        font-weight: 600;
        height: 63px;
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
        padding-left: 20px;
      }
    }

    .message {
      background: #fff;
      padding: 10px;
      min-width: 25%;
      border: 1px solid #fff;
      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      border-radius: 7px;
      color: #2c2c2c;
      max-width: 70%;
      font-family: "Poppins", Arial, sans-serif;
      &.left {
        margin-right: auto;
      }
      &.right {
        margin-left: auto;
      }
    }

    .inner-inbox {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column-reverse;
      gap: 10px;
      overflow-y: scroll;
      overflow-x: hidden;
      background: #f8f8f8;
      border-radius: 8px;
      padding: 8px;
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: #c4bdbd;
        border-radius: 10px;
      }
    }

    .no-chats {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h2 {
        margin-top: 5px;
        color: #2c2c2c99;
        font-family: "Poppins", Arial, sans-serif;
      }
    }
  }
`;

const LoadingBox = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`;
