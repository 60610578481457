import { ConfigProvider, Modal, Select, Switch, TimePicker } from "antd";
import React from "react";
import styled from "styled-components";
import { Field, Form, Formik } from "formik";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import ErrorMessage from "../utils/ErrorMessage";
import InputField from "../validations/InputField";
import { DropDownIcon } from "../utils/SvgIcons";

const SourceOption = [
  {
    label: "Yelp",
    value: "yelp",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "Web",
    value: "web",
  },
];

const WorkingDaysOption = [
  {
    label: "Monday",
    value: "Mon",
  },
  {
    label: "Tuesday",
    value: "Tue",
  },
  {
    label: "Wednesday",
    value: "Wed",
  },
  {
    label: "Thurday",
    value: "Thu",
  },
  {
    label: "Friday",
    value: "Fri",
  },
  {
    label: "Saturday",
    value: "Sat",
  },
  {
    label: "Sunday",
    value: "Sun",
  },
];

export function FilterModal({
  open,
  handleClose,
  editDetails,
  setFilterData,
  setCurrentPage,
  setFilterPayload,
}) {
  const initialValues = editDetails || {};

  const handleSubmit = async (values) => {
    setFilterData(values);
    const filterArr = [];
    for (const key in values) {
      // eslint-disable-next-line no-prototype-builtins
      if (values.hasOwnProperty(key)) {
        const requestObject = {
          field: key,
          value: values[key],
        };
        if (values[key]) {
          filterArr.push(requestObject);
        }
      }
    }
    setFilterPayload(filterArr);
    handleClose();
    setCurrentPage(1);
  };

  return (
    <div>
      <Modal
        prefixCls="add-company"
        open={open}
        centered
        onCancel={handleClose}
        footer={false}
        closeIcon={null}
      >
        <AddCompanyWrapper>
          <div className="title">
            <h3>Filters</h3>
          </div>
          <div>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              render={({ setFieldValue, values, errors, touched }) => (
                <Form>
                  <div className="content">
                    <div className="fields">
                      <label>Decline Service</label>
                      <Field
                        type="text"
                        name="decline_service"
                        className="input"
                        placeholder="Enter decline service"
                        component={InputField}
                      />
                    </div>
                    <div className="fields">
                      <label>Source Type</label>
                      <Select
                        prefixCls="inner-select3"
                        className="inner-select2"
                        value={values?.type}
                        suffixIcon={false}
                        allowClear
                        placeholder="Select Source type"
                        onChange={(e) => setFieldValue("type", e)}
                        options={SourceOption}
                      />
                      <div className="img-icon">
                        <DropDownIcon />
                      </div>
                      {errors?.type && touched?.type && (
                        <ErrorMessage message={errors?.type} />
                      )}
                    </div>

                    <div className="fields">
                      <label>Working Days</label>
                      <Select
                        // mode="multiple"
                        maxTagCount={2}
                        prefixCls="inner-select3"
                        value={values?.working_days}
                        className="inner-select2"
                        suffixIcon={false}
                        allowClear
                        placeholder="Select Working days"
                        onChange={(e) => setFieldValue("working_days", e)}
                        options={WorkingDaysOption}
                      />
                      <div className="img-icon">
                        <DropDownIcon />
                      </div>
                      {errors?.working_days && touched?.working_days && (
                        <ErrorMessage message={errors?.working_days} />
                      )}
                    </div>

                    <div className="fields">
                      <label>Operation Hours</label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <TimePicker
                            value={
                              values?.operation_start
                                ? dayjs(`2022-09-12 ${values?.operation_start}`)
                                : null
                            }
                            placeholder="Start Time"
                            onChange={(_, time) =>
                              setFieldValue("operation_start", time)
                            }
                            needConfirm={false}
                            format="HH:mm"
                            prefixCls="date-picker"
                            className="time-picker"
                          />
                          {errors?.operation_start &&
                            touched?.operation_start && (
                              <ErrorMessage message={errors?.operation_start} />
                            )}
                        </div>
                        {/* <span
                          style={{
                            margin: "0 8px",
                            color: "#fff",
                            fontFamily: "Poppins",
                          }}
                        >
                          to
                        </span> */}
                        <div style={{ width: "100%" }}>
                          <TimePicker
                            value={
                              values?.operation_end
                                ? dayjs(`2022-09-12 ${values?.operation_end}`)
                                : null
                            }
                            placeholder="End Time"
                            needConfirm={false}
                            onChange={(_, time) =>
                              setFieldValue("operation_end", time)
                            }
                            prefixCls="date-picker"
                            className="time-picker"
                            format="HH:mm"
                          />
                          {errors?.operation_end && touched?.operation_end && (
                            <ErrorMessage message={errors?.operation_end} />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="switch-wrap">
                      <div style={{ width: "100%" }} className="fields">
                        <label>After Hours</label>
                        <ConfigProvider
                          theme={{
                            components: {
                              Switch: {
                                colorPrimary: "#296eff",
                                handleBg: "#fff",
                                colorPrimaryHover: "#296eff",
                                colorTextQuaternary: "#2c2c2c",
                                colorTextTertiary: "#2c2c2c",
                              },
                            },
                          }}
                        >
                          <Switch
                            prefixCls="custom-switch"
                            value={values?.after_hours}
                            onChange={(e) => setFieldValue("after_hours", e)}
                          />
                        </ConfigProvider>
                      </div>
                      <div style={{ width: "100%" }} className="fields">
                        <label>Active </label>
                        <ConfigProvider
                          theme={{
                            components: {
                              Switch: {
                                colorPrimary: "#296eff",
                                handleBg: "#fff",
                                colorPrimaryHover: "#296eff",
                                colorTextQuaternary: "#2c2c2c",
                                colorTextTertiary: "#2c2c2c",
                              },
                            },
                          }}
                        >
                          <Switch
                            prefixCls="custom-switch"
                            value={values?.active}
                            onChange={(e) => setFieldValue("active", e)}
                          />
                        </ConfigProvider>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <button onClick={handleClose}>CANCEL</button>

                    <button className="btn-save" type="submit">
                      APPLY
                    </button>
                  </div>
                </Form>
              )}
            />
          </div>
        </AddCompanyWrapper>
      </Modal>
    </div>
  );
}

FilterModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  editDetails: PropTypes.object,
  setFilterData: PropTypes.func,
  setCurrentPage: PropTypes.any,
  setFilterPayload: PropTypes.any,
};

const AddCompanyWrapper = styled.div`
  width: 100%;
  padding: 24px;
  max-height: 96vh;
  padding-left: 20px;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #00000045;
    border-radius: 10px;
  }

  .title {
    h3 {
      color: #2c2c2c;
      font-family: "Poppins";
      font-size: 23px;
    }
  }

  .content {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 14px;

    .fields {
      display: flex;
      flex-direction: column;
      gap: 6px;
      position: relative;

      .img-icon {
        position: absolute;
        right: 20px;
        top: 42px;
        cursor: pointer;
        svg {
          ${
            "" /* filter: ${({ themeValue }) => (themeValue === "day" ? "invert(0)" : "invert(1)")}; */
          }
        }
      }

      label {
        color: #2c2c2c;
        font-family: "Poppins";
        font-size: 13px;
      }
      .input {
        background: transparent;
        border: 1px solid #cecece;
        border-radius: 10px;
        color: #000;
        &:focus {
          outline: none;
        }
      }

      .textarea {
        background: #000;
        border: 1px solid #cecece;
        border-radius: 10px;
        width: 100% !important;
        height: 64px !important;
        color: #fff;
        resize: none;
        padding: 10px;
        font-family: "Poppins" !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        &::placeholder {
          color: #535e6d;
          font-family: "Poppins" !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
  .inner-select2 {
    color: #535e6d;
    font-family: "Poppins" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    appearance: none;
    height: 50px;
    width: 100%;
    padding: 0px 0px;
    border-radius: 10px;
    border: 1px solid #cecece !important;

    .inner-select2-selection-overflow {
      margin-top: -4px !important;
      margin-left: 11px !important;
    }
  }

  .time-picker {
    color: #000;
    font-family: "Poppins" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    height: 50px;
    width: 100%;
    padding: 0px 0px;
    border-radius: 10px;
    border: 1px solid #cecece !important;
    display: flex !important;
    justify-content: space-around !important;
    background-color: transparent;
  }
  .btn-wrapper {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 26px;

    @media (max-width: 480px) {
      flex-direction: column-reverse;
      gap: 10px;
    }

    button {
      width: 100%;
      display: flex;
      height: 48px;
      padding: 21px 24px;
      justify-content: center;
      align-items: center;
      background: transparent;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid #2c2c2c;
      color: #2c2c2c;
      text-align: center;
      cursor: pointer;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: 0.2px;
    }

    .btn-save {
      background: #296eff;
      color: #fff;
      border: none;
    }
  }
  .switch-wrap {
    display: flex;
  }
`;
