import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { PublicPaths } from "./PublicPaths";
import { PrivatePaths } from "./PrivatePaths";
import { PrivateLayout } from "../layouts/PrivateLayout";
import PageNotFound from "../module/PageNotFound";
import { YelpTable } from "../module/yelp/YelpTable";
import Conversation from "../module/conversations/Conversation";
import { BookingForOneCompany } from "../module/booking/BookingForOneCompany";
import AiBooking from "../module/aiBookingStats/AiBooking";
import { LsaTable } from "../module/lsa/LsaTable";
import { SmsTable } from "../module/sms/SmsTable";

function PublicRoute({ isAuthenticated, userDetails }) {
  if (isAuthenticated !== undefined || isAuthenticated != null) {
    if (userDetails?.isCompanyUser) {
      return <Navigate to="/chats" replace />;
    }
    return <Navigate to="/dashboard" replace />;
  }
  return <Outlet />;
}

// This function is used for Authentication when user's credentials will be invalid.
function PrivateRoute({ isAuthenticated }) {
  if (isAuthenticated === undefined || isAuthenticated == null) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
}

export function AppRoutes() {
  const credentials = useSelector((state) => state?.LoginSlice?.data?.token);
  const userDetails = useSelector((state) => state?.LoginSlice?.data);

  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route
        element={
          <PublicRoute
            isAuthenticated={credentials}
            userDetails={userDetails}
          />
        }
      >
        {PublicPaths?.map((el, idx) => (
          <Route key={idx} path={el?.path} element={el?.component} />
        ))}
      </Route>
      <Route element={<PrivateRoute isAuthenticated={credentials} />}>
        {!userDetails?.isCompanyUser ? (
          <Route element={<PrivateLayout />}>
            {PrivatePaths?.map((el, idx) => (
              <Route key={idx} path={el?.path} element={el?.component} />
            ))}
          </Route>
        ) : (
          <Route element={<PrivateLayout />}>
            <Route path="/yelps" element={<YelpTable />} />
            <Route path="/lsa" element={<LsaTable />} />
            <Route path="/sms" element={<SmsTable />} />
            <Route path="/chats/:company_id?" element={<Conversation />} />
            <Route path="/booking" element={<BookingForOneCompany />} />
            <Route path="/ai-booking-stats" element={<AiBooking />} />
          </Route>
        )}
      </Route>
    </Routes>
  );
}

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.string,
  userDetails: PropTypes.object,
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.string,
};
