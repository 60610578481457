import { Modal } from "antd";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

function BookingModal({ open, handleClose, data }) {
  return (
    <Modal
      prefixCls="add-company"
      open={open}
      centered
      onCancel={handleClose}
      footer={false}
      closeIcon={null}
    >
      <Container>
        <div className="title">
          <h3>Booking Details</h3>
        </div>
        <div className="content">
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>First name</div>
            <div>{data?.book_data?.first_name}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>Last name</div>
            <div>{data?.book_data?.last_name}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>Phone</div>
            <div>{data?.book_data?.phone}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>Email</div>
            <div>{data?.book_data?.email}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>Client name</div>
            <div>{data?.book_data?.client_name}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>source</div>
            <div>{data?.book_data?.source}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>country</div>
            <div>{data?.book_data?.country}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>state</div>
            <div>{data?.book_data?.state}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>city</div>
            <div>{data?.book_data?.city}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>street</div>
            <div>{data?.book_data?.street}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>zip</div>
            <div>{data?.book_data?.zip ? data?.book_data?.zip : "N/A"}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>priority</div>
            <div>{data?.book_data?.priority}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>Integration type</div>
            <div>{data?.book_data?.integration_type}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>Book option</div>
            <div>{data?.book_data?.book_option}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>Appointment date</div>
            <div>{data?.book_data?.appointment_date}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>Appointment time</div>
            <div>{data?.book_data?.appointment_time}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>Technician id</div>
            <div>{data?.book_data?.technicianid}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>tenant id</div>
            <div>{data?.book_data?.tenantid}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>tag id</div>
            <div>{data?.book_data?.tagid}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>campaign id</div>
            <div>{data?.book_data?.campaignid}</div>
          </div>

          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>business Unit id</div>
            <div>{data?.book_data?.businessUnitId}</div>
          </div>
          <div className="fields">
            <div style={{ textTransform: "capitalize" }}>booking provider</div>
            <div>{data?.book_data?.booking_provider}</div>
          </div>
        </div>
      </Container>
    </Modal>
  );
}

BookingModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object,
};

export default BookingModal;

const Container = styled.div`
  width: 100%;
  padding: 24px;
  max-height: 96vh;
  padding-left: 20px;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #00000045;
    border-radius: 10px;
  }

  .title {
    h3 {
      color: #2c2c2c;
      font-family: "Poppins";
      font-size: 23px;
    }
  }
  .content {
    width: 100%;
    margin-top: 20px;

    .fields {
      padding: 10px;
      color: #2c2c2c;
      font-family: "Poppins";
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cecece30;
    }
  }
`;
