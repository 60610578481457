import React, { useCallback, useEffect, useState } from "react";
import { Drawer, Pagination, Table } from "antd";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import TableLoader from "../../components/TableLoader";
import { Sort, SortAsc, SortDesc } from "../../utils/Images";
import { getBookingList } from "../../services/Collection";
import { debounce } from "../../utils/CommonFunction";
import BookingModal from "../../components/BookingModal";
import { BackSVGIcon } from "../../utils/SvgIcons";

function BookingList() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [pagesize, setPageSize] = useState(10);
  const [extraData, setExtraData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [sortParam, setSortParam] = useState({
    sortType: "",
    sortBasis: "",
  });
  const location = useLocation();
  const [openChatDrawer, setOpenChatDrawer] = useState(false);
  const [openSmsDrawer, setOpenSmsDrawer] = useState(false);
  const [editDetails, setEditDetails] = useState(null);
  const [detailModal, setDetailModal] = useState(false);

  const getSortParam = (sortBasis) => {
    setCurrentPage(1);
    if (sortBasis === sortParam.sortBasis) {
      setSortParam({
        sortType: sortParam.sortType === "desc" ? "asc" : "desc",
        sortBasis,
      });
    } else {
      setSortParam({
        sortType: "desc",
        sortBasis,
      });
    }
  };

  const srcSortImage = (Basis) => {
    if (Basis === sortParam.sortBasis) {
      if (sortParam.sortType === "asc") {
        return SortAsc;
      }
      return SortDesc;
    }
    return Sort;
  };

  const onChangePagination = (e, limit) => {
    setCurrentPage(e);
    setPageSize(limit);
  };

  const handleBookingList = async (search) => {
    setLoading(true);
    const id = location?.state?.id;
    const params = new URLSearchParams();
    params.append("page", Number(currentPage));
    params.append("page_size", Number(pagesize));
    params.append("company_id", id);
    sortParam?.sortBasis && params.append("sort_by", sortParam?.sortBasis);
    sortParam?.sortType && params.append("order_by", sortParam?.sortType);
    if (search !== null || search !== "") {
      if (search === undefined) {
        if (searchWord) params.append("search", searchWord);
      } else {
        search && params.append("search", search);
      }
    }
    const res = await getBookingList(id, params?.toString());
    if (res?.status === 200) {
      setLoading(false);
      setExtraData(res?.total_count);
      if (res?.data?.length > 0) {
        const arr = [];
        // eslint-disable-next-line array-callback-return
        res?.data?.map((el, index) => {
          const obj = {
            ...el,
            index: pagesize * (currentPage - 1) + (1 + index),
          };
          arr.push(obj);
        });
        setTableData(arr);
      } else {
        setTableData([]);
        // setExtraData(0);
      }
    } else {
      setLoading(false);
    }
  };

  const handleSearchListing = useCallback(debounce(handleBookingList), []);

  const handleSearch = (value) => {
    setSearchWord(value);
    handleSearchListing(value);
  };

  const handleChatIframeIds = (data) => {
    if (data?.message_id != null) {
      return `https://bookingwidget.adleverage.com/admin/company/${location?.state?.id}/${data?.message_id}/detail`;
    }
    if (data?.yelp_message_id != null) {
      return `https://bookingwidget.adleverage.com/admin/company/${location?.state?.id}/${data?.yelp_message_id}/yelp`;
    }
    if (data?.lsa_message_id != null) {
      return `https://bookingwidget.adleverage.com/admin/company/${location?.state?.id}/${data?.lsa_message_id}/lsa`;
    }
    return null;
  };

  const handleChatButtonStyling = (data) => {
    if (
      data?.message_id != null ||
      data?.yelp_message_id != null ||
      data?.lsa_message_id != null
    ) {
      return false;
    }
    return true;
  };

  const handleChatButton = (data) => {
    if (data?.message_id != null) {
      return "Chat";
    }
    if (data?.yelp_message_id != null) {
      return "Yelp Chat";
    }
    if (data?.lsa_message_id != null) {
      return "LSA Chat";
    }
    return "Chat";
  };

  useEffect(() => {
    handleBookingList();
  }, [currentPage, pagesize, sortParam]);

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "index",
      render: (val) => <p>{val}.</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Email</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "email" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("email")}
            onClick={() => getSortParam("email")}
          />
        </TitleWrapper>
      ),
      dataIndex: "email",
    },
    {
      title: (
        <TitleWrapper>
          <span>First name</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "first_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("first_name")}
            onClick={() => getSortParam("first_name")}
          />
        </TitleWrapper>
      ),
      dataIndex: "first_name",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Last name</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "last_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("last_name")}
            onClick={() => getSortParam("last_name")}
          />
        </TitleWrapper>
      ),
      dataIndex: "last_name",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Phone</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "phone" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("phone")}
            onClick={() => getSortParam("phone")}
          />
        </TitleWrapper>
      ),
      dataIndex: "phone",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: "Chat",
      render: (_, data) => (
        <PodiumStatusWrapper>
          <button
            style={{
              border: handleChatButtonStyling(data) && "1px solid gray",
              color: handleChatButtonStyling(data) && "gray",
              cursor: handleChatButtonStyling(data) && "not-allowed",
            }}
            className="btn-connected"
            onClick={() => {
              if (!handleChatButtonStyling(data)) {
                setEditDetails(data);
                setOpenChatDrawer(true);
              }
            }}
          >
            {handleChatButton(data)}
          </button>
          <button
            style={{
              border: !data?.sms_message_id && "1px solid gray",
              color: !data?.sms_message_id && "gray",
              cursor: !data?.sms_message_id && "not-allowed",
            }}
            className="btn-connected"
            onClick={() => {
              if (data?.sms_message_id) {
                setEditDetails(data);
                setOpenSmsDrawer(true);
              }
            }}
          >
            SMS
          </button>
        </PodiumStatusWrapper>
      ),
    },
    {
      title: (
        <TitleWrapper>
          <span>Booking Status</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "status" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("status")}
            onClick={() => getSortParam("status")}
          />
        </TitleWrapper>
      ),
      dataIndex: "status",
      render: (val) => <p>{val ? "True" : "N/A"}</p>,
    },
    {
      title: "Action",
      render: (_, data) => (
        <PodiumStatusWrapper>
          <button
            style={{
              color: "#f98229",
              border: "1px solid #f98229",
              display: "flex",
              gap: "5px",
            }}
            className="btn-connected"
            onClick={() => {
              setEditDetails(data);
              setDetailModal(true);
            }}
          >
            Details
          </button>
        </PodiumStatusWrapper>
      ),
    },
  ];

  return (
    <TableWrapper>
      {openChatDrawer && (
        <Drawer
          className="filter-drawer"
          placement="right"
          //   closable={false}
          onClose={() => {
            setOpenChatDrawer(false);
          }}
          open={openChatDrawer}
          width="100%"
          height="100%"
          key="bottom"
        >
          <iframe
            src={handleChatIframeIds(editDetails)}
            style={{ height: "100%", width: "100%" }}
            loading="lazy"
          />
          {/* <ChatInbox>
            {true && (
              <div className="loading-box">
                <TableLoader data={[]} />
              </div>
            )}
          </ChatInbox> */}
        </Drawer>
      )}
      {openSmsDrawer && (
        <Drawer
          className="filter-drawer"
          placement="right"
          //   closable={false}
          onClose={() => {
            setOpenSmsDrawer(false);
          }}
          open={openSmsDrawer}
          width="100%"
          height="100%"
          key="bottom"
        >
          <iframe
            src={`https://bookingwidget.adleverage.com/admin/company/${location?.state?.id}/${editDetails?.sms_message_id}/sms`}
            style={{ height: "100%", width: "100%" }}
            loading="lazy"
          />
          {/* <ChatInbox>
            {true && (
              <div className="loading-box">
                <TableLoader data={[]} />
              </div>
            )}
          </ChatInbox> */}
        </Drawer>
      )}

      {detailModal && (
        <BookingModal
          open={detailModal}
          handleClose={() => setDetailModal(false)}
          data={editDetails}
        />
      )}
      <div className="create-btn">
        <div className="backbtn-box">
          <span
            style={{
              cursor: "pointer",
              display: "flex",
              gap: "5px",
              fontFamily: "Poppins",
              color: "#000",
            }}
            onClick={() => navigate(-1)}
          >
            <BackSVGIcon /> Back
          </span>
        </div>
        <div>
          <form autoComplete="off" onSubmit={(e) => e?.preventDefault()}>
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => {
                e?.preventDefault();
                setCurrentPage(1);
                handleSearch(e?.target?.value);
              }}
              autoComplete="off"
            />
          </form>
        </div>
        {/* <div className="btn">
          <button
            style={{ width: "80px" }}
            // onClick={() => setFilterModal(true)}
          >
            Filters
          </button>
        </div> */}
      </div>
      <div style={{ position: "relative" }}>
        <Table
          prefixCls="campaignTable"
          columns={columns}
          dataSource={tableData}
          pagination={false}
        />
        {loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#ffffff66",
            }}
          >
            {/* Render your Lottie animation */}
            <TableLoader data={tableData} />
          </div>
        )}
      </div>
      {!loading && (
        <div style={{ marginTop: "10px" }}>
          <Pagination
            prefixCls="interaction-pagination-night"
            current={currentPage}
            onChange={onChangePagination}
            total={extraData}
            defaultPageSize={pagesize}
            showSizeChanger
          />
        </div>
      )}
    </TableWrapper>
  );
}

export default BookingList;

const TableWrapper = styled.div`
  background: #f2f5fd;
  min-height: calc(100vh - 82px);
  height: calc(100% - 82px);
  height: 100%;
  padding: 30px;
  padding-top: 0px;

  .create-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0px;
    gap: 7px;

    .backbtn-box {
      display: flex;
      align-items: center;
    }

    input {
      height: 43px;
      width: 100%;
      background: #ffff;
      border-radius: 7px;
      border: 1px solid #cecece;
      padding: 0 10px;
      color: #000;
      font-family: "Poppins", Arial, sans-serif;

      &:focus {
        outline: none;
        border: 1px solid #000;
      }
    }

    .btn {
      button {
        background-color: #343434;
        color: #fff;
        font-family: "Poppins";
        border-radius: 7px;
        font-weight: 600;
        font-size: 13px;
        border: none;
        width: 100px;
        height: 43px;
        cursor: pointer;
      }
    }
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 10px;

  img {
    cursor: pointer;
    height: 20px;
  }
`;

const PodiumStatusWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  .btn-connect {
    border-radius: 12px;
    padding: 5px 10px;
    border-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    font-family: "Poppins", Arial, sans-serif;

    cursor: pointer;
  }
  .btn-connected {
    border-radius: 12px;
    padding: 5px 10px;
    border-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #71b497;
    color: #71b497;
    width: 90px;
    font-family: "Poppins", Arial, sans-serif;

    cursor: pointer;
  }
`;
