import React from "react";
import styled from "styled-components";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BookingStats,
  ChatIcon,
  CompanyUserSVG,
  Dashboard,
  YelpSVGIcon,
} from "../utils/SvgIcons";
import { OpenSideNav } from "../auth/LoginSlice";
import { updateMessageType, updateSelectedChat } from "../store/MessageSlice";

export default function Sidenav() {
  const location = useLocation()?.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.LoginSlice?.data);

  const navigateRoutes = (params) => {
    navigate(params);
    dispatch(OpenSideNav(false));
    dispatch(updateMessageType("chatwidget"));
    dispatch(updateSelectedChat(null));
  };

  return (
    <SidenavWrapper>
      {/* <header>
        <img src={MainLogo2} alt="" />
      </header> */}

      <section>
        <div className="all-tabs">
          {!userDetails?.isCompanyUser && (
            <div
              className={location.includes("dashboard") && "active"}
              onClick={() => navigateRoutes("/dashboard")}
            >
              <Dashboard />
              <p>Companies</p>
            </div>
          )}
          {/* {!userDetails?.isCompanyUser && (
            <div
              className={location.includes("podium") && "active"}
              onClick={() => navigateRoutes("/podium")}
            >
              <PodiumIcon />
              <p>Podium</p>
            </div>
          )} */}
          {/* {userDetails?.isCompanyUser && ( */}
          <div
            className={location.includes("chats") && "active"}
            onClick={() => navigateRoutes("/chats")}
          >
            <ChatIcon />
            <p>Conversations</p>
          </div>
          {/* )} */}
          <div
            className={location.includes("yelps") && "active"}
            onClick={() => navigateRoutes("/yelps")}
          >
            <YelpSVGIcon />
            <p>Manage Yelp Users</p>
          </div>
          <div
            className={location.includes("lsa") && "active"}
            onClick={() => navigateRoutes("/lsa")}
          >
            <CompanyUserSVG />
            <p>Manage LSA Users</p>
          </div>
          <div
            className={location.includes("sms") && "active"}
            onClick={() => navigateRoutes("/sms")}
          >
            <ChatIcon />
            <p>Manage SMS Users</p>
          </div>
          {(!userDetails?.isCompanyUser || userDetails?.is_super_admin) && (
            <div
              className={location.includes("company-users") && "active"}
              onClick={() => navigateRoutes("/company-users")}
            >
              <CompanyUserSVG />
              <p>Company Users</p>
            </div>
          )}

          {(userDetails?.isCompanyUser || userDetails?.is_super_admin) && (
            <div
              className={location.includes("ai-booking-stats") && "active"}
              onClick={() => navigateRoutes("/ai-booking-stats")}
            >
              <BookingStats />
              <p>Booking Buddy Performance</p>
            </div>
          )}
        </div>
        {/* <div className="sidebar-footer">
          <div>
            <Setting />
            <p>Settings</p>
          </div>
          <div>
            <Support />
            <p>Support</p>
          </div>
        </div> */}
      </section>
    </SidenavWrapper>
  );
}

const SidenavWrapper = styled.div`
  width: 100%;
  background: #fff;
  border-right: 12px solid #f2f5fd;
  padding: 15px 16px;

  header {
    position: relative;
    text-align: center;
    img {
      width: 80px;
    }
  }

  .Three-lines {
    position: absolute;
    top: 14px;
    right: 10px;
    cursor: pointer;
  }

  section {
    width: 100%;
    height: calc(100vh - 78px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .all-tabs {
      width: 100%;
      color: #2c2c2c;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      .active {
        background: #296eff;
        border-radius: 10px;
        color: #fff;

        svg {
          fill: #fff !important;

          path {
            stroke: #fff !important;
          }
        }
      }

      div {
        display: flex;
        align-items: center;
        justify-content: start;
        margin: 8px 0;
        cursor: pointer;

        padding: 12px 16px;

        p {
          margin: 0px;
        }

        svg {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
    }
    .sidebar-footer {
      width: 100%;
      margin: 32px 0;
      color: #ffffff;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;

      div {
        display: flex;
        align-items: center;
        justify-content: start;
        margin: 8px 0;
        cursor: pointer;
        // background: #343434;
        border-radius: 500px;
        padding: 12px 16px;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
    }
  }
`;
