import React, { useCallback, useContext, useEffect, useState } from "react";
import { Pagination, Table, Tooltip } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { TableWrapper, TitleWrapper } from "../yelp/YelpTable";
import YelpFilterModal from "../../modals/YelpFilterModal";
import { debounce, srcSortImage } from "../../utils/CommonFunction";
import {
  deleteCompanyUser,
  getCompanyUsersList,
} from "../../services/Collection";
import TableLoader from "../../components/TableLoader";
import CreateCompanyUser from "../../modals/CreateCompanyUser";
import { DeleteIcon, EditIcon } from "../../utils/SvgIcons";
import { DeleteModal } from "../../components/DeleteModal";
import { success } from "../../utils/ApiSuccessMessage";
import { MessageContext } from "../../App";
import { error } from "../../utils/ApiErrorMessage";
import { SignInAction } from "../../auth/LoginSlice";

function CompanyUserList() {
  const [tableData, setTableData] = useState([]);
  const [sortParam, setSortParam] = useState({
    sortType: "",
    sortBasis: "",
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [extraData, setExtraData] = useState(0);
  const [loader, setLoader] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [filterPayload, setFilterPayload] = useState([]);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [editDetails, setEditDetails] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const messageApi = useContext(MessageContext);
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const dispatch = useDispatch();

  const getSortParam = (sortBasis) => {
    if (sortParam?.sortBasis === sortBasis) {
      const filterObject = {
        sortBasis,
        sortType: sortParam?.sortType === "desc" ? "asc" : "desc",
      };
      setSortParam(filterObject);
    } else {
      const filterObject = {
        sortBasis,
        sortType: "desc",
      };
      setSortParam(filterObject);
    }
  };

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "index",
      render: (val) => <p>{val}.</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Name</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("name", sortParam)}
            onClick={() => getSortParam("name")}
          />
        </TitleWrapper>
      ),
      dataIndex: "name",
    },
    {
      title: (
        <TitleWrapper>
          <span>Email</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "email" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("email", sortParam)}
            onClick={() => getSortParam("email")}
          />
        </TitleWrapper>
      ),
      dataIndex: "email",
      render: (val) => (
        <div>
          <Tooltip prefixCls="tooltip-box" title={val}>
            <p
              style={{
                maxWidth: "220px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {val}
            </p>
          </Tooltip>
        </div>
      ),
    },
    {
      title: (
        <TitleWrapper>
          <span>Company Id</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "company_id" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("company_id", sortParam)}
            onClick={() => getSortParam("company_id")}
          />
        </TitleWrapper>
      ),
      dataIndex: "company_id",
    },
    {
      title: (
        <TitleWrapper>
          <span>Is Active</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "is_active" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("is_active", sortParam)}
            onClick={() => getSortParam("is_active")}
          />
        </TitleWrapper>
      ),
      dataIndex: "is_active",
      render: (val) => <span>{val === true ? "True" : "False"}</span>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Is Super Admin</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "is_super_admin" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("is_super_admin", sortParam)}
            onClick={() => getSortParam("is_super_admin")}
          />
        </TitleWrapper>
      ),
      dataIndex: "is_super_admin",
      render: (val) => <span>{val === true ? "True" : "False"}</span>,
    },
    {
      title: "Actions",
      render: (_, data) => (
        <ActionButtons>
          <div
            className="edit-icon"
            onClick={() => {
              setEditDetails(data);
              setEditUserModal(true);
            }}
          >
            <EditIcon />
          </div>
          <div
            className="delete-icon"
            onClick={() => {
              setEditDetails(data);
              setDeleteUserModal(true);
            }}
          >
            <DeleteIcon />
          </div>
        </ActionButtons>
      ),
    },
  ];

  const onChangePagination = (e, limit) => {
    setPage(e);
    setPageSize(limit);
  };

  const handleCompanyUserListing = async (search) => {
    setLoader(true);
    const params = new URLSearchParams();
    params.append("page_size", pageSize);
    params.append("page", page);
    sortParam?.sortBasis && params.append("sort_by", sortParam?.sortBasis);
    sortParam?.sortType && params.append("order_by", sortParam?.sortType);
    if (search !== null || search !== "") {
      if (search === undefined) {
        if (searchWord) params.append("search", searchWord);
      } else {
        search && params.append("search", search);
      }
    }

    let companyUserPayload = [];
    if (userDetails?.isCompanyUser && userDetails?.is_super_admin) {
      companyUserPayload = [
        { field: "company_id", value: userDetails?.company_id },
      ];
    }
    const res = await getCompanyUsersList(
      params,
      companyUserPayload?.length > 0
        ? companyUserPayload
        : filterPayload?.length > 0 && filterPayload
    );
    setExtraData(res?.total_count);
    if (res?.status === 200) {
      const array = res?.data?.map((el, idx) => ({
        ...el,
        index: pageSize * (page - 1) + (1 + idx),
      }));
      setTableData(array);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const handleSearchListing = useCallback(debounce(handleCompanyUserListing), [
    filterData,
  ]);

  const handleSearch = (value) => {
    setSearchWord(value);
    handleSearchListing(value);
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    const res = await deleteCompanyUser(editDetails?.id);
    if (res?.status === 200) {
      setDeleteLoading(false);
      handleCompanyUserListing();
      setDeleteUserModal(false);
      success(messageApi, "User Deleted Successfully");
      if (editDetails?.id === userDetails?.id) {
        dispatch(SignInAction(null));
        success(
          messageApi,
          "The session has been logged out because you deleted your own account."
        );
      }
    } else {
      const message =
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong";
      error(messageApi, message);
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    handleCompanyUserListing();
  }, [sortParam, page, pageSize, filterData]);

  return (
    <TableWrapper>
      {filterModal && (
        <YelpFilterModal
          editDetails={filterData}
          handleClose={() => setFilterModal(false)}
          open={filterModal}
          setCurrentPage={setPage}
          setFilterData={setFilterData}
          setFilterPayload={setFilterPayload}
        />
      )}
      {createUserModal && (
        <CreateCompanyUser
          open={createUserModal}
          handleClose={() => setCreateUserModal(false)}
          modalType="add"
          listApi={handleCompanyUserListing}
        />
      )}
      {editUserModal && (
        <CreateCompanyUser
          open={editUserModal}
          handleClose={() => setEditUserModal(false)}
          modalType="edit"
          editDetails={editDetails}
          listApi={handleCompanyUserListing}
        />
      )}
      {deleteUserModal && (
        <DeleteModal
          open={deleteUserModal}
          handleClose={() => setDeleteUserModal(false)}
          title="Are you sure you want to Delete this User ?"
          description="All your process and data will be saved."
          isLoading={deleteLoading}
          handleSubmit={handleDelete}
        />
      )}
      <div className="create-btn">
        <div>
          <form autoComplete="off" onSubmit={(e) => e?.preventDefault()}>
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => {
                e?.preventDefault();
                setPage(1);
                handleSearch(e?.target?.value);
              }}
              autoComplete="off"
            />
          </form>
        </div>
        <div className="btn" style={{ display: "flex", gap: "7px" }}>
          {userDetails?.is_super_admin && (
            <button
              style={{ width: "80px" }}
              onClick={() => setFilterModal(true)}
            >
              Filters
            </button>
          )}
          <button
            style={{ width: "80px" }}
            onClick={() => setCreateUserModal(true)}
          >
            Create
          </button>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <Table
          prefixCls="campaignTable"
          columns={columns}
          dataSource={tableData ?? []}
          pagination={false}
        />
        {loader && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#ffffff66",
            }}
          >
            <TableLoader data={tableData} />
          </div>
        )}
      </div>
      {!loader && (
        <div style={{ marginTop: "10px" }}>
          <Pagination
            prefixCls="interaction-pagination-night"
            current={page}
            onChange={onChangePagination}
            total={extraData}
            defaultPageSize={pageSize}
            showSizeChanger
          />
        </div>
      )}
    </TableWrapper>
  );
}

export default CompanyUserList;

const ActionButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 6px;

  .edit-icon {
    background: #f98229;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    display: grid;
    cursor: pointer;
    place-items: center;
  }
  .delete-icon {
    background: red;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    display: grid;
    cursor: pointer;
    place-items: center;
  }
`;
