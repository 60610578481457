import React, { useContext, useState } from "react";
import { Modal } from "antd";
import { Field, Form, Formik } from "formik";
import { styled } from "styled-components";
import * as yup from "yup";
import PropTypes from "prop-types";
import InputField from "../validations/InputField";
import { getYelpAuthURL } from "../services/Collection";
import { MessageContext } from "../App";
import { error } from "../utils/ApiErrorMessage";

export function YelpConnectModal({ open, handleClose, editDetails }) {
  const messageApi = useContext(MessageContext);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    business_name: "",
    business_id: "",
  };

  const validationSchema = yup.object().shape({
    business_name: yup.string().required("Business Name is required"),
    business_id: yup.string().required("Business Id is required"),
  });
  const handleSubmit = async (values) => {
    setLoading(true);
    const requestPayload = {
      ...values,
      company_id: editDetails?.id,
    };
    const res = await getYelpAuthURL(requestPayload);
    if (res?.status === 200) {
      if (res?.url) {
        handleClose();
        window.location.href = res?.url;
        setLoading(false);
      } else {
        setLoading(false);
        error(messageApi, "URL not found");
      }
    } else {
      handleClose();
    }
  };

  return (
    <Modal
      width={500}
      open={open}
      onCancel={handleClose}
      footer={false}
      closeIcon={false}
      prefixCls="add-company"
    >
      <AddCompanyWrapper>
        <div className="title">
          <h3>Yelp Connection</h3>
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            render={() => (
              <Form>
                <div className="content">
                  <div className="fields">
                    <label>
                      Business Id{" "}
                      <span style={{ color: "red", fontSize: "15px" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      name="business_id"
                      className="input"
                      placeholder="Enter business id"
                      component={InputField}
                    />
                  </div>
                  <div className="fields">
                    <label>
                      Business Name{" "}
                      <span style={{ color: "red", fontSize: "15px" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      name="business_name"
                      className="input"
                      placeholder="Enter business name"
                      component={InputField}
                    />
                  </div>
                </div>
                <div className="btn-wrapper">
                  <button onClick={handleClose}>CANCEL</button>

                  {!loading ? (
                    <button className="btn-save" type="submit">
                      CONNECT
                    </button>
                  ) : (
                    <button className="btn-save">Loading...</button>
                  )}
                </div>
              </Form>
            )}
          />
        </div>
      </AddCompanyWrapper>
    </Modal>
  );
}

YelpConnectModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  editDetails: PropTypes.object,
};

export const AddCompanyWrapper = styled.div`
  width: 100%;
  padding: 24px;
  max-height: 96vh;
  padding-left: 20px;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #00000045;
    border-radius: 10px;
  }

  .title {
    h3 {
      color: #2c2c2c;
      font-family: "Poppins";
      font-size: 23px;
    }
  }

  .content {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 14px;

    .fields {
      display: flex;
      flex-direction: column;
      gap: 6px;
      position: relative;

      .img-icon {
        position: absolute;
        right: 20px;
        top: 45px;
        cursor: pointer;
        ${
          "" /* svg {
          filter: ${({ themeValue }) =>
            themeValue === "day" ? "invert(0)" : "invert(1)"};
        } */
        }
      }

      label {
        color: #2c2c2c;
        font-family: "Poppins";
        font-size: 13px;
      }
      .input {
        background: transparent;
        border: 1px solid #cecece;
        border-radius: 10px;
        color: #000;
        &:focus {
          outline: none;
        }
      }

      .textarea {
        background: #000;
        border: 1px solid #cecece;
        border-radius: 10px;
        width: 100% !important;
        height: 64px !important;
        color: #fff;
        resize: none;
        padding: 10px;
        font-family: "Poppins" !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        &::placeholder {
          color: #535e6d;
          font-family: "Poppins" !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
  .inner-select2 {
    color: #535e6d;
    font-family: "Poppins" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    appearance: none;
    height: 50px;
    width: 100%;
    padding: 0px 0px;
    border-radius: 10px;
    border: 1px solid #cecece !important;

    .inner-select2-selection-overflow {
      margin-top: -4px !important;
      margin-left: 11px !important;
    }
  }

  .time-picker {
    color: #535e6d;
    font-family: "Poppins" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    height: 50px;
    width: 100%;
    padding: 0px 0px;
    border-radius: 10px;
    border: 1px solid #cecece !important;
    display: flex !important;
    justify-content: space-around !important;
    background-color: #000;
  }
  .btn-wrapper {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 26px;

    @media (max-width: 480px) {
      flex-direction: column-reverse;
      gap: 10px;
    }

    button {
      width: 100%;
      display: flex;
      height: 48px;
      padding: 21px 24px;
      justify-content: center;
      align-items: center;
      background: transparent;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid #2c2c2c;
      color: #2c2c2c;
      text-align: center;
      cursor: pointer;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: 0.2px;
    }

    .btn-save {
      background: #296eff;
      color: #fff;
      border: none;
    }
  }
  .switch-wrap {
    display: flex;
  }
`;
