import { ConfigProvider, Modal, Select, Spin, Switch } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { AddCompanyWrapper } from "./YelpConnectModal";
import InputField from "../validations/InputField";
import { DropDownIcon } from "../utils/SvgIcons";
import ErrorMessage from "../utils/ErrorMessage";
import {
  createCompanyUsers,
  editCompanyUsers,
  getCompanyListing,
} from "../services/Collection";
import { error } from "../utils/ApiErrorMessage";
import { MessageContext } from "../App";
import { SignInAction } from "../auth/LoginSlice";
import { success } from "../utils/ApiSuccessMessage";

function CreateCompanyUser({
  open,
  handleClose,
  modalType,
  editDetails,
  listApi,
}) {
  const [loading, setLoading] = useState(false);
  const [companyloading, setCompanyLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [scrollPage, setScrollPage] = useState(1);
  const messageApi = useContext(MessageContext);
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const dispatch = useDispatch();

  const initialValues = {
    name: editDetails?.name ? editDetails?.name : "",
    email: editDetails?.email ? editDetails?.email : "",
    password: editDetails?.password ? "******" : "",
    company_id: editDetails?.company_id
      ? { value: editDetails?.company_id }
      : null,
    is_super_admin: editDetails?.is_super_admin
      ? editDetails?.is_super_admin
      : false,
    is_active: editDetails?.is_active ? editDetails?.is_active : false,
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is required"),
    password: yup.string().required("Password is required").min(3),
    company_id: yup.object().required("Company is required"),
  });

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
      setScrollPage(scrollPage + 1);
    }
  };

  const handleCompanyList = async () => {
    setCompanyLoading(true);
    const params = new URLSearchParams();
    params.append("page", scrollPage);
    params.append("page_size", 100);

    const res = await getCompanyListing(params);
    if (res?.data?.length > 0) {
      const array = res?.data?.map((el) => {
        const obj = {
          label: el?.name,
          value: el?.id,
        };
        return obj;
      });
      if (scrollPage === 1) {
        setCompanyList(array);
      } else {
        setCompanyList([...companyList, ...array]);
      }
      setCompanyLoading(false);
    } else {
      setCompanyLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const requestPayload = {
      ...values,
      company_id: values?.company_id?.value,
    };
    const res = await createCompanyUsers(requestPayload);
    if (res?.status === 200) {
      handleClose();
      listApi();
      setLoading(false);
    } else {
      const message =
        res?.response?.data?.detail ||
        res?.message ||
        res?.error ||
        "Something went wrong";
      error(messageApi, message);
      setLoading(false);
    }
  };

  const handleUpdateData = async (values) => {
    setLoading(true);
    const requestPayload = {
      name: values?.name,
      email: values?.email,
      password: values?.password,
      is_active: values?.is_active,
      is_super_admin: values?.is_super_admin,
      company_id: values?.company_id?.value,
    };
    if (requestPayload?.password === "******") delete requestPayload.password;
    const res = await editCompanyUsers(editDetails?.id, requestPayload);
    if (res?.status === 200) {
      handleClose();
      listApi();
      setLoading(false);
      success(messageApi, "User Updated Successfully");
      if (editDetails?.id === userDetails?.id) {
        if (userDetails?.is_super_admin !== requestPayload?.is_super_admin) {
          dispatch(SignInAction(null));
          success(
            messageApi,
            "The session has been logged out because you edit your role."
          );
        }
      }
    } else {
      const message =
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong";
      error(messageApi, message);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleCompanyList();
  }, [scrollPage]);

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      centered
      footer={false}
      closeIcon={<span />}
      prefixCls="add-company"
    >
      <AddCompanyWrapper>
        <div className="title">
          <h3>{modalType == "edit" ? "Edit" : "Add"} Company User</h3>
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={modalType === "edit" ? handleUpdateData : handleSubmit}
            render={({ setFieldValue, values, errors, touched }) => (
              <Form>
                <div className="content">
                  <div className="fields">
                    <label>
                      Name{" "}
                      <span style={{ color: "red", fontSize: "15px" }}>*</span>
                    </label>
                    <Field
                      className="input"
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                      component={InputField}
                    />
                  </div>
                  <div className="fields">
                    <label>
                      Email{" "}
                      <span style={{ color: "red", fontSize: "15px" }}>*</span>
                    </label>
                    <Field
                      className="input"
                      name="email"
                      type="text"
                      placeholder="Enter Email"
                      component={InputField}
                    />
                  </div>

                  <div className="fields">
                    <label>
                      Password{" "}
                      <span style={{ color: "red", fontSize: "15px" }}>*</span>
                    </label>
                    <Field
                      className="input"
                      name="password"
                      type="text"
                      placeholder="Enter password"
                      component={InputField}
                    />
                  </div>

                  {userDetails?.is_super_admin && (
                    <div className="fields">
                      <label>
                        Select Company{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>
                      <Select
                        prefixCls="inner-select3"
                        className="inner-select2"
                        value={companyloading ? null : values?.company_id}
                        loading={companyloading}
                        onPopupScroll={handleScroll}
                        suffixIcon={false}
                        showSearch
                        allowClear
                        placeholder="Select Source type"
                        onChange={(e, data) =>
                          setFieldValue("company_id", data)
                        }
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        options={companyList}
                      />
                      {companyloading ? (
                        <div className="img-icon">
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{
                                  fontSize: 15,
                                }}
                                spin
                              />
                            }
                          />
                        </div>
                      ) : (
                        <div className="img-icon">
                          <DropDownIcon />
                        </div>
                      )}

                      {errors?.company_id && touched?.company_id && (
                        <ErrorMessage message={errors?.company_id} />
                      )}
                    </div>
                  )}

                  <div className="switch-wrap">
                    <div style={{ width: "100%" }} className="fields">
                      <label>Is Active</label>
                      <ConfigProvider
                        theme={{
                          components: {
                            Switch: {
                              colorPrimary: "#296eff",
                              handleBg: "#fff",
                              colorPrimaryHover: "#296eff",
                              colorTextQuaternary: "#2c2c2c",
                              colorTextTertiary: "#2c2c2c",
                            },
                          },
                        }}
                      >
                        <Switch
                          prefixCls="custom-switch"
                          value={values?.is_active}
                          onChange={(e) => setFieldValue("is_active", e)}
                        />
                      </ConfigProvider>
                    </div>
                    <div style={{ width: "100%" }} className="fields">
                      <label>Is Super Admin</label>
                      <ConfigProvider
                        theme={{
                          components: {
                            Switch: {
                              colorPrimary: "#296eff",
                              handleBg: "#fff",
                              colorPrimaryHover: "#296eff",
                              colorTextQuaternary: "#2c2c2c",
                              colorTextTertiary: "#2c2c2c",
                            },
                          },
                        }}
                      >
                        <Switch
                          prefixCls="custom-switch"
                          value={values?.is_super_admin}
                          onChange={(e) => setFieldValue("is_super_admin", e)}
                        />
                      </ConfigProvider>
                    </div>
                  </div>
                </div>
                <div className="btn-wrapper">
                  <button onClick={handleClose}>CANCEL</button>

                  {loading ? (
                    <button className="btn-save">Loading...</button>
                  ) : (
                    <button className="btn-save" type="submit">
                      {modalType == "edit" ? "SAVE" : "CREATE"}
                    </button>
                  )}
                </div>
              </Form>
            )}
          />
        </div>
      </AddCompanyWrapper>
    </Modal>
  );
}

export default CreateCompanyUser;

CreateCompanyUser.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  modalType: PropTypes.string,
  editDetails: PropTypes.object,
  listApi: PropTypes.func,
};
